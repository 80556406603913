import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as CONFIG from '../../constants/config';
import * as MESSAGES from '../../constants/messages';

const LandingApp = () => (
  <div className='container'>
      <img alt="FIC-CAT" style={{ width: '100px', margin: '0 20px 10px 0' }} src="/img/logo.png" />
      <h2>El FIC-CAT a la teva butxaca</h2>
      <p style={{ maxWidth: '760px', padding: '0 0 20px'}} >Descarrrega l'app del FIC-CAT pel teu sistema Android o Apple:</p>
      <p style={{ textAlign: 'center' }}>
        <a href='https://apps.apple.com/es/app/fic-cat/id1567804675?l=ca'>
         <img src="/img/app-store-badge.svg" style={{ width:'300px', margin: '0 0 0 10px'}} />
        </a>
      <a href='https://play.google.com/store/apps/details?id=cat.woow.ficcatapp'>
         <img src="/img/google-play-badge.png" style={{ width:'340px'}} />
      </a>

      </p>
  </div>
);

export default LandingApp;
