import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import FileUploader from '../FileUploader';
import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';

import { getImageSize } from 'react-image-size';
import { isEmpty } from 'underscore';
import Loading from '../Loading';
import { Alert, Card, Container } from 'react-bootstrap';
import FilmsBadges from '../FilmsBadges';
import FilmsEditImages from '../FilmsEditImages';
import { FaUnlockAlt } from 'react-icons/fa';
import { PiCheckCircleFill, PiWarningDiamondFill } from 'react-icons/pi';

//import FilmList from '../Films';

let filmType = '';
let filmUid = '';
let edition = '';
const rolesVariants = ['','-1','-2','-3']

if (localStorage.getItem('currentEdition') === null ) {
  edition = CONFIG.CURRENT_EDITION;
} else {
  edition = localStorage.getItem('currentEdition');
} 

let orderSession = (sessionGroup) => {
  return _.sortBy(sessionGroup, "time");
};

class HomePage extends Component {
  constructor(props) {
    super(props);
    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    filmUid = props.match.params.uid ? props.match.params.uid : null;
    this.state = {
      type: null,
      validated: null,
      selectedSession: {
        date: null,
        uid: null
      }
    }

    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.type = 'JOVES';
      break;
      case 'llargs':
      default:
        this.state.type = 'LLARGS';
      break;
    }
    
  }

  componentDidMount () {
    // empty
  }
  render () {
    return (
          <Films />
    )
  }
}

class FilmsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      film: null,
      API: null,
      sessions: null,
    };

    

    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.short(filmUid);
        this.filmTypeName = 'CURTS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.doc(filmUid);
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.school(filmUid);
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.music(filmUid);
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.API = this.props.firebase.young(filmUid);
        this.filmTypeName = 'JOVES';
      break;
      case 'llargs':
        default:
          this.state.API = this.props.firebase.film(filmUid);
          this.filmTypeName = 'LLARGS';
        break;
    }

   
  }
  async fetchImageSize(imageUrl, newField) {
      try {
          const a1 = new Image;
          a1.src=imageUrl;
          await getImageSize(imageUrl).then(
            result => this.setState({ [newField] : result }
          ));
      } catch (error) {
      }
  }
  
  
  componentDidMount() {

    let scopeKeeper =  this;
    this.setState({ loading: true });


    this.state.API.once('value', snapshot => {
      
      if (snapshot) {
        // convert messages list from snapshot
        this.setState({
          film: snapshot.val(),
          loading: false,
        });

        this.setState({ selectedSession: {
          date: this.state.film && this.state.film.session && this.state.film.session.date ? this.state.film.session.date : '',
          uid: this.state.film && this.state.film.session && this.state.film.session.uid ?  this.state.film.session.uid : ''
        }})
    
        
        this.fetchImageSize(this.state.film.imageCover, 'imageCoverDimensions');
        this.fetchImageSize(this.state.film.imageFrame1, 'imageFrame1Dimensions');
        this.fetchImageSize(this.state.film.imageFrame2, 'imageFrame2Dimensions');
        this.fetchImageSize(this.state.film.imageFrame3, 'imageFrame3Dimensions');

        this.updateVideoIframe();

        this.props.firebase.sessions().on("value", (snapshot) => {
          const sessionObject = snapshot.val();
          if (sessionObject) {
            const sessionList = Object.keys(sessionObject).map((key) => ({
              ...sessionObject[key],
              uid: key,
            }));
            // convert messages list from snapshot
    
            let groupedSessions = _.map(_.groupBy(_.filter( sessionList, function(session) { 
    
              switch(session.type) {
                case filmType:
                  return true
                break;
                case "primaria":
                  if (scopeKeeper.state.film.filmSchoolLevel === 'primaria') {
                    return true;
                  } else {
                    return false;
                  }
                break;
                case "secundaria":
                  if (scopeKeeper.state.film.filmSchoolLevel === 'secundaria') {
                    return true;
                  } else {
                    return false;
                  }
                break;
                case "standard":
                  if (filmType === 'curts' ||  filmType === 'llargs' || filmType === 'documentals' ) {
                    return true;
                  } else {
                    return false;
                  }
                break;
                  
                default:
                  return false
                  break;
    
              }
    
             } ), "date"));
    
             scopeKeeper.setState({
              sessions: groupedSessions,
            });
          } else {
            scopeKeeper.setState({ sessions: null, loading: false });
          }
        });
        
      } else {
        this.setState({ film: null, loading: false });
      }

      this.setState({ 
        isSelectable: this.state.film.isSelectable,
        isSelected: this.state.film.isSelected
      });

    });
  }

  updateVideoIframe() {
    if (this.state.film && (this.state.film.filmLink || this.state.film.eventLinkYouTube)) {
      // - Supported YouTube URL formats:
      //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
      //   - http://youtu.be/My2FRPA3Gf8
      //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
      // - Supported Vimeo URL formats:
      //   - http://vimeo.com/25451551
      //   - http://player.vimeo.com/video/25451551
      // - Also supports relative URLs:
      //   - //player.vimeo.com/video/25451551

      let filmEmbed = this.state.film.eventLinkYouTube != undefined ? this.state.film.eventLinkYouTube : this.state.film.filmLink; 
  
      if ( filmEmbed.indexOf('drive.google.com') > -1) {
        filmEmbed.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|drive.google\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|file\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?\/([A-Za-z0-9._%-]*)(\S+)?/);
        this.setState( { film: {...this.state.film, filmLinkType:'drive' ,filmLinkId: RegExp.$8 } })
      } else if (filmEmbed.indexOf('youtu.be') > -1) {
        this.setState( { film: {...this.state.film, filmLinkType:'youtube' ,filmLinkId: filmEmbed.split('/')[3] } })  
      } else {
        filmEmbed.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/);
        if (RegExp.$3.indexOf('vimeo') > -1) {
          this.setState( { film: {...this.state.film, filmLinkType:'vimeo' ,filmLinkId: RegExp.$6 } })
        } else if (RegExp.$3.indexOf('drive.google.com') > -1) {
          this.setState( { film: {...this.state.film, filmLinkType:'drive' ,filmLinkId: RegExp.$6 } })
        } else if (RegExp.$3.indexOf('youtube') > -1) {
          this.setState( { film: {...this.state.film, filmLinkType:'youtube' ,filmLinkId: RegExp.$6 } })
        }
      }
    }
  }

  handleUploadSuccess = (url, imageField) => {

    if (url !== undefined) {

      let data = this.state.film;

      data[imageField] = url;

      this.setState({
        [imageField]: url
      });

      if (data.filmName && data[imageField]) {
        this.state.API.set(data);    
      }
    }
    
  }

  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }

  updateIs(e) {

    let data = this.state.film;
    let modified = false;


    if (data.filmName) {
      switch(e.target.type) {
        case 'checkbox':
          data[e.target.name] = JSON.parse(e.target.checked);
          modified = true;
        break;
        case 'select-one':
          data[e.target.name] = e.target.value;
          modified = true;
        break;
      }
      if (modified) {
        this.setState({ film: data })
        this.state.API.set(data);    
      }
    }

  }


  updateSession(e) {

    let date = document.getElementById('sessionDate').value;
    let sessionUid = document.getElementById('sessionTime').value;

    this.setState({ selectedSession: {
      date: date
    }})

    if (date && sessionUid) {

      // GET ORIGINAL DATA
      let data = this.state.film;

      // OVERWRITE THE VALUES WITH THE ONES IN THE FORM


      this.props.firebase.database().ref('films/' + edition + '/SESSION/'+ sessionUid).on('value', snapshot => {
      
        if (snapshot) {
            // GET ORIGINAL DATA

            data.session = snapshot.val();
            data.sessionUid = sessionUid;
      
            // STORE DATA
            this.state.API.set(data);

        }
      })

    }
 
  
  }
  
  sendFormBasicData(e) {
   
    e.preventDefault();

    const form = e.currentTarget;

    this.setState({ validated: true });

    // GET ORIGINAL DATA
    let data = this.state.film;

    if (form.checkValidity() === false) {
      document.documentElement.scrollTop = 0;
      e.stopPropagation();
    } else {

        // OVERWRITE THE VALUES WITH THE ONES IN THE FORM
        data.filmName = form.elements['filmName'].value;
        data.filmDirector = form.elements['filmDirector'].value;
        data.producerCompany = form.elements['producerCompany'].value;

        data.filmLength = form.elements['filmLength'].value;
        data.filmYear = form.elements['filmYear'].value;
        data.filmCity = form.elements['filmCity'].value;
        data.filmState = form.elements['filmState'].value;

        data.filmActoring = form.elements['filmActoring'].value;
        data.filmSynopsis = form.elements['filmSynopsis'].value;
        
        // STORE DATA
        this.state.API.set(data);
        this.setState({ success: true });
    }
  };

  sendFormVideo(e) {
   
    e.preventDefault();

    const form = e.currentTarget;

    this.setState({ validated: true });

    // GET ORIGINAL DATA
    let data = this.state.film;

    if (form.checkValidity() === false) {
      document.documentElement.scrollTop = 0;
      e.stopPropagation();
    } else {

        data.filmLink = form.elements['filmLink'].value;
        data.filmPass = form.elements['filmPass'].value;
        
        // STORE DATA
        this.state.API.set(data);
        this.setState({ success: true, film: data });
        this.updateVideoIframe();

    }
  };

  sendFormContactData(e) {
   
    e.preventDefault();

    const form = e.currentTarget;

    this.setState({ validated: true });

    // GET ORIGINAL DATA
    let data = this.state.film;

    if (form.checkValidity() === false) {
      document.documentElement.scrollTop = 0;
      e.stopPropagation();
    } else {

      rolesVariants.map((modifier, key) => {
        data['contactName' + modifier] = form.elements['contactName' + modifier].value;
        data['contactRole' + modifier] = form.elements['contactRole' + modifier].value;
        data['contactEmail' + modifier] = form.elements['contactEmail' + modifier].value;
        data['contactPhone' + modifier] = form.elements['contactPhone' + modifier].value;
      })

        // STORE DATA
        this.state.API.set(data);
        this.setState({ success: true });
    }
  };

  render() {
    const { film, sessions, selectedSession } = this.state;
    return (
      <Container className='p-0 m-0'>
        { (film && ( U.isUserContentManager() )) ? (
          <>
            <Container>
            <h1>{film.filmName}</h1>
            </Container>
            <Tabs
              defaultActiveKey={ film.isInfoReady ? ( film.isImageReady ? 'material' : 'images') : 'dades'}
              id="edit-film-tabs"
              className="mb-3"
            >
          <Tab eventKey="dades" title={ (film.isSelected ? (film.isInfoReady ? '\u2705' : '\u274C') : '' ) + ' Info'} className='pt-2'>
            <Container>
          <Row>
              <Col sm={8}>
                  <Row className='my-4'>
                    <Col sm>
                    <h2>Informació bàsica <small>Dades que apareixen al catàleg.</small></h2>
                    </Col>
                    <Col sm>
                      
                      <Alert variant='info' className='text-center mb-0'>
                      <FaUnlockAlt />
                    <Form id="isInfoReadyForm" ref='isInfoReadyForm' >  
                      <Form.Check
                        inline
                        label='Marcar com a revisat'
                        id='isInfoReady'
                        name='isInfoReady' 
                        onChange={this.updateIs.bind(this)}
                        type='switch'
                        defaultChecked={film.isInfoReady === true}
                      />
                    </Form>
                    </Alert>
                    </Col>
                  </Row>
                  <Form noValidate validated={ this.state.validated } id="formBasicData" className='pb-4 mb-4' onSubmit={this.sendFormBasicData.bind(this)}>
                    <Row>
                      <Form.Group controlId="filmName" className='mb-3'>
                        <Form.Label>Títol de la producció</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          defaultValue={film.filmName ? film.filmName : '' }
                          placeholder='Ex: La gran pel·lícula'
                        />
                        <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="filmDirector" className='mb-3'>
                        <Form.Label>Director/a</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          placeholder='Ex: Joana Roig'
                          defaultValue={film.filmDirector ? film.filmDirector : '' }
                        />
                        <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="producerCompany" className='mb-3'>
                        <Form.Label>Empreses productores (separades per coma)</Form.Label>
                        <Form.Control
                          required
                          defaultValue={film.producerCompany ? film.producerCompany : '' }
                          type='text'
                          placeholder='Ex: Produccions exemplars, ABC, DEF'
                        />
                        <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group  className="mb-3" controlId="filmYear">
                          <Form.Label>Any de producció</Form.Label>
                          <Form.Control
                            required
                            type='number'
                            placeholder={CONFIG.CURRENT_EDITION}
                            defaultValue={film.filmYear ? film.filmYear : '' }
                          />
                          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="filmLength" className='mb-3'>
                          <Form.Label>Durada (min.)</Form.Label>
                          <Form.Control
                            required
                            defaultValue={film.filmLength ? film.filmLength : '' }
                            type='number'
                            placeholder='Ex: 120'
                            step="1"
                          />
                          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm>
                        <Form.Group  className="mb-3" controlId="filmCity">
                          <Form.Label>Localitat</Form.Label>
                          <Form.Control
                            required
                            type='text'
                            placeholder='Ex: Roda de Berà'
                            defaultValue={film.filmCity ? film.filmCity : '' }
                          />
                          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm>
                        <Form.Group  className="mb-3" controlId="filmState">
                          <Form.Label>Província</Form.Label>
                          <Form.Control as="select"
                            required
                            defaultValue={film.filmState ? film.filmState : '' }
                          >
                            <option value=''>Selecciona una opció...</option>
                            { CONFIG.SPAIN_STATES.map((t,k) => <option key={k} value={t}>{t}</option>) } 
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Form.Group  className="mb-3" controlId="filmActoring">
                        <Form.Label>Intèrprets principals (fes servir comes per separar els noms)</Form.Label>
                        <Form.Control
                          as='textarea'
                          required
                          rows="3"
                          placeholder='Ex: Júlia Roig, Pere Verd....'
                          defaultValue={film.filmActoring ? film.filmActoring : '' }
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group  className="mb-3" controlId="filmSynopsis">
                        <Form.Label>Sinopsi (inferior a 350 caràcters)</Form.Label>
                        <Form.Control
                          as='textarea'
                          required
                          rows='4'
                          maxLength='350'
                          placeholder='Ex: Un noi coneix a una noia i....'
                          defaultValue={film.filmSynopsis ? film.filmSynopsis : '' }
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Button type='submit' className='btn btn-lg btn-success'><strong>Actualitzar dades</strong></Button>
                  </Form>
              </Col>
              <Col sm={4}>
                <Card className='bg-secondary p-4 mt-4'>
                  <h2 className='mb-4'>Programació <small>Marca els criteris que es compleixin.</small></h2>
                  <Form className='pb-4' id="isSelectableForm" ref='isSelectableForm' >  
                    <Form.Check
                      inline
                      label='Compleix les bases'
                      className={film.isSelectable === true ? 'text-success font-weight-bold' : 'text-muted'} 
                      id='isSelectable'
                      name='isSelectable'
                      disabled={!U.isUserAdmin()}
                      onChange={this.updateIs.bind(this)}
                      type='switch'
                      defaultChecked={film.isSelectable === true}
                    />
                  </Form>
                  
                  <Form className='pb-2' id="isFirstForm" ref='isFirstForm' >  
                    <Form.Check
                      inline
                      label='Òpera Prima'
                      id='isFirst'
                      name='isFirst'
                      onChange={this.updateIs.bind(this)}
                      type='switch'
                      defaultChecked={film.isFirst === true}
                    />
                  </Form>
                  <Form className='pb-2' id="isPremiereForm" ref='isPremiereForm' > 
                    <hr /> 
                    <Form.Check
                      inline
                      label='Estrena'
                      className={film.isPremiere === true ? 'font-weight-bold' : 'text-muted'} 
                      id='isPremiere' 
                      name='isPremiere' 
                      onChange={this.updateIs.bind(this)}
                      type='switch'
                      defaultChecked={film.isPremiere === true}
                    />
                    <Form.Group className='mt-2' controlId="isPremiereType">
                      <Form.Control as="select"
                        required
                        name="isPremiereType"
                        defaultValue={film.isPremiereType ? film.isPremiereType : ''}
                        onChange={this.updateIs.bind(this)}
                        disabled={film.isPremiere !== true }
                      >
                        <option value=''>Selecciona una opció...</option>
                        <option value='Catalunya'>Catalunya</option>
                        <option value='Espanya'>Espanya</option>
                        <option value='Europa'>Europa</option>
                        <option value='Mundial'>Mundial</option>
                        </Form.Control>
                    </Form.Group>
                  </Form>
                  <hr />
                  <Form className='pb-2' id="isSelectedForm" ref='isSelectedForm' >  
                    <Form.Check
                      inline
                      label='Selecció Oficial'
                      id='isSelected'
                      name='isSelected' 
                      onChange={this.updateIs.bind(this)}
                      type='switch'
                      disabled={ (film.isSelectable !== true || !U.isUserAdmin()) }
                      defaultChecked={film.isSelected === true}
                    />
                  </Form>
                  <Form>
                  { sessions && ( 
                    <div>
                      <Form.Group className='mt-2' controlId="sessionDate">
                        <Form.Control as="select"
                          required
                          name="sessionDate"
                          defaultValue={ film.session && film.session.date ? film.session.date : ''}
                          onChange={this.updateSession.bind(this)}
                          disabled={film.isSelected !== true }
                        >
                          <option value=''>Selecciona una data...</option>
                            {sessions.map((sessionGroup, keyGroup) => (
                            <option value={sessionGroup[0].date} key={'sessionGroup-'+keyGroup}>
                              {sessionGroup[0].date}
                            </option>
                          ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className='mt-2' controlId="sessionTime">
                          <Form.Control as="select"
                            required
                            name="sessionTime"
                            onChange={this.updateSession.bind(this)}
                            value={ film.sessionUid && film.sessionUid ? film.sessionUid : ''}
                            disabled={film.isSelected !== true }
                          >
                            <option value=''>Selecciona hora...</option>
                              {sessions.map((sessionGroup, keyGroup) => (
                                (selectedSession && sessionGroup[0].date === selectedSession.date) && (
                                  orderSession(sessionGroup).map((session, key) => (
                                    <option value={session.uid} key={'session-'+ key}>{session.time} ({session.name})</option>
                                  ))
                                )
                              ))}
                            </Form.Control>
                        </Form.Group>
                    </div>
                  )}
                    <Form.Group className='mt-2' controlId="sessionOrder">
                      <Form.Control as="select"
                        required
                        name="sessionOrder"
                        onChange={this.updateIs.bind(this)}
                        defaultValue={ film.sessionOrder && film.sessionOrder ? film.sessionOrder : ''}
                        disabled={film.isSelected !== true }
                      >
                        <option value=''>Selecciona ordre...</option>
                          {['1','2','3','4','5','6','7','8','9','10','11','12','13','15','15'].map((order, key) => (
                            <option value={order} key={'order-'+ key}>{order}</option>
                          ))}
                        </Form.Control>
                    </Form.Group>
                  </Form>
                  
                  <Form className='pb-2' id="isTarragona" ref='isTarragonaForm' >  
                    <hr />
                    <Form.Check
                      inline
                      label='Comarques Tarragonines'
                      className={film.isTarragona === true ? 'font-weight-bold' : 'text-muted'} 
                      id='isTarragona' 
                      name='isTarragona' 
                      onChange={this.updateIs.bind(this)}
                      type='switch'
                      defaultChecked={film.isTarragona === true}
                    />
                    <p className='pt-3'><small className='text-muted'>{film.filmTarragonaStaff !== true ? (<><PiWarningDiamondFill className='icon text-warning' /><strong> No confirmat:</strong></>) : (<PiCheckCircleFill className='icon text-success' />)} L’equip de direcció i/o producció és provinent de les Comarques Tarragonines.</small></p>
                    <p><small className='text-muted'>{film.filmTarragonaLocation !== true ? (<><PiWarningDiamondFill className='icon text-warning' /><strong> No confirmat:</strong></>) : (<PiCheckCircleFill className='icon text-success' />)} La producció ha estat rodada a les Comarques Tarragonines (totalment o parcial)</small></p>
                    <p className='mb-0'><small className='text-muted'>{ CONFIG.TARRAGONA_CITIES.indexOf(film.filmCity) !== -1 ? (<PiCheckCircleFill className='icon text-success' />) : (<PiWarningDiamondFill className='icon text-warning' />)} {film.filmCity}
                    &nbsp;&nbsp;&nbsp;{(film.filmState && film.filmState.toUpperCase().indexOf('TARRAGONA') !== -1 ) ? (<PiCheckCircleFill className='icon text-success' />) : (<PiWarningDiamondFill className='icon text-warning' />)} {film.filmState ? film.filmState : '?'}</small></p>		
                  </Form>
                  <Form className='pb-2' id="isWormanForm" ref='isWormanForm' >  
                    <hr />
                    <Form.Check
                      inline
                      label='Participat per dones'
                      id='isWoman'
                      name='isWoman' 
                      onChange={this.updateIs.bind(this)}
                      type='switch'
                      defaultChecked={film.isWoman === true}
                    />
                    
                    <p className='pt-3'><small className='text-muted'>{film.filmDirectorGenre !== 'Dona' ? (<><PiWarningDiamondFill className='icon text-warning' /></>) : (<PiCheckCircleFill className='icon text-success' />)} Direcció: {film.filmDirectorGenre}</small></p>
                    <p><small className='text-muted'>{film.producerGenre !== 'Dona' ? (<><PiWarningDiamondFill className='icon text-warning' /></>) : (<PiCheckCircleFill className='icon text-success' />)} Producció: {film.producerGenre}</small></p>
                    <p className='mb-0'><small className='text-muted'>{film.writerGenre !== 'Dona' ? (<><PiWarningDiamondFill className='icon text-warning' /></>) : (<PiCheckCircleFill className='icon text-success' />)} Guió: {film.writerGenre}</small></p>
                  </Form>
              </Card>
              </Col>
          </Row>
          </Container>
          </Tab>
          
          <Tab eventKey="images" title={ (film.isSelected ? (film.isImageReady ? '\u2705' : '\u274C') : '' ) + ' Imatges'}  className='pt-2'>
            <Container>
              <Row className='my-4'>
                <Col sm>
                < h2>Imatges <small>Dades que apareixen al catàleg.</small></h2>
                </Col>
                <Col sm>
                  <Alert variant='info' className='text-center mb-0'>
                  <FaUnlockAlt />
                  <Form id="isImageReadyForm" ref='isImageReadyForm' >  
                    <Form.Check
                      inline
                      label='Marcar com a revisades'
                      id='isImageReady'
                      name='isImageReady' 
                      onChange={this.updateIs.bind(this)}
                      type='switch'
                      defaultChecked={film.isImageReady === true}
                    />
                  </Form>
                </Alert>
                </Col>
              </Row>
            <Row>
              <Col sm>
                <FilmsEditImages filmUid={filmUid} film={film} filmType={filmType} imageName="imageCover" mode="admin" />
              </Col>
              <Col sm>
                <FilmsEditImages filmUid={filmUid} film={film} filmType={filmType} imageName="imageFrame1" mode="admin" />
              </Col>
              <Col sm>
                 <FilmsEditImages filmUid={filmUid} film={film} filmType={filmType} imageName="imageFrame2" mode="admin" />
              </Col>
              <Col sm>
                <FilmsEditImages filmUid={filmUid} film={film} filmType={filmType} imageName="imageFrame3" mode="admin"/>
              </Col>
            </Row>
            </Container>
          </Tab>
          <Tab eventKey="material" title={ (film.isSelected ? (film.isDownloaded ? film.isOnline ? '\u2705' : '⚠️' : '\u274C') : '' ) + ' Material'} className='pt-2'>
            <Container>
              { film.isSelected  && (
                <>
              <div className='text-end pb-2'>
                <a href={ROUTES['ITEM_STUFF'] + '/' + filmType + '/' + edition  + '/' + filmUid } className=''>Gestió de Materials</a>
              </div>
              <Card  className='mb-4'>
                <Card.Body>
              <Row>
                <Col sm>
                  < h2>Premi en línea</h2>
                </Col>
                <Col sm>
                  <Alert variant='info' className='text-center mb-0'>
                  <FaUnlockAlt />
                    <Form className='pb-2' id="isOnlineForm" ref='isOnlineForm' > 
                        <Form.Check
                          inline
                          label='Marcar com a disponible 3CAT'
                          id='isOnline'
                          name='isOnline' 
                          onChange={this.updateIs.bind(this)}
                          type='switch'
                          disabled={!film.acceptOnlineSigned }
                          defaultChecked={film.isOnline === true}
                        />
                      </Form>
                      <Form className='pb-2' id="isOnlineForm" ref='isOnlineForm' > 
                        <Form.Check
                          inline
                          label='Pujat al FTP'
                          id='acceptOnlineUploaded'
                          name='acceptOnlineUploaded' 
                          onChange={this.updateIs.bind(this)}
                          type='switch'
                          disabled={!film.isOnline || !film.acceptOnlineDownload }
                          defaultChecked={film.acceptOnlineUploaded === true}
                        />
                      </Form>
                  </Alert>
                </Col>
              </Row>
              <Row>
                <dl>
                    <dt>Interès en estar en línea</dt>
                    <dd>
                      {film.acceptOnline === 'false'  ? (
                      <>
                        <PiWarningDiamondFill className='icon text-warning' /> No ha mostrat interès
                      </>
                    ) : (
                      <>
                      <PiCheckCircleFill className='icon text-success' /> Ha mostrat interès
                      </>
                      )}
                    </dd>
                    <dt>Cessió de drets</dt>
                    <dd className='text-truncate'>
                      {!film.acceptOnlineSigned ? (
                      <>
                        <PiWarningDiamondFill className='icon text-warning' /> No ha signat la cessió de drets
                      </>
                    ) : (
                      <>
                      <PiCheckCircleFill className='icon text-success' />
                        &nbsp;<a href={film.acceptOnlineSigned} target='_blank'>{film.acceptOnlineSigned}</a>
                      </>
                      )}
                    </dd>
                    <dt>Gestió de la conversió</dt>
                    <dd className='text-truncate'>
                      {!film.acceptOnlineDownloadType ? (
                      <>
                        <PiWarningDiamondFill className='icon text-warning' />&nbsp; No s'ha especificat encara
                      </>
                    ) : (
                      <>
                      <PiCheckCircleFill className='icon text-success' />
                        &nbsp;{film.acceptOnlineDownloadType ? film.acceptOnlineDownloadType.toUpperCase() : ''}
                      </>
                      )}
                    </dd>
                    <dt>Link de descàrrega</dt>
                    <dd className='text-truncate'>
                      {!film.acceptOnlineDownload ? (
                      <>
                        <PiWarningDiamondFill className='icon text-warning' />&nbsp; No ha link encara
                      </>
                    ) : (
                      <>
                      <PiCheckCircleFill className='icon text-success' />
                        &nbsp;<a href={film.acceptOnlineDownload} target='_blank'>{film.acceptOnlineDownload}</a>
                      </>
                      )}
                    </dd>
                </dl>
                
              </Row>
            </Card.Body>
          </Card>
          <Card className='mb-4'>
            <Card.Body>
            <Row>
              <Col sm>
              < h2>Descàrrega d'arxius i promoció <small></small></h2>
              </Col>
              <Col sm>
              <Alert variant='info' className='text-center mb-0'>
                <FaUnlockAlt />
                <Form id="isDownloadedForm" ref='isDownloadedForm' >  
                  <Form.Check
                    inline
                    label='Marcar com a descarregat'
                    id='isDownloaded'
                    name='isDownloaded' 
                    onChange={this.updateIs.bind(this)}
                    type='switch'
                    disabled={ !film.filmLinkDownload }
                    defaultChecked={film.isDownloaded === true}
                  />
                </Form>
                </Alert>
              </Col>
            </Row>
            <Row>
            <dl>
                <dt>Enllaç de descàrrega per la projecció</dt>
                <dd className='text-truncate' >
                  {!film.filmLinkDownload ? (
                  <>
                    <PiWarningDiamondFill className='icon text-warning' /> No hi ha link de descàrrega
                  </>
                ) : (
                  <>
                  <PiCheckCircleFill className='icon text-success' />
                  <a href={film.filmLinkDownload} target='_blank'>{film.filmLinkDownload}</a>
                  </>
                  )}
                </dd>
            </dl>
            <dl>
                <dt>Enllaç Trailer/Videoclip (Streaming)</dt>
                <dd className='text-truncate' >
                  {!film.filmLinkTrailer ? (
                  <>
                    <PiWarningDiamondFill className='icon text-warning' /> No hi ha link
                  </>
                ) : (
                  <>
                  <PiCheckCircleFill className='icon text-success' />
                  <a href={film.filmLinkTrailer} target='_blank'>{film.filmLinkTrailer}</a>
                  </>
                  )}
                </dd>
            </dl>
            </Row>
            </Card.Body>
          </Card>
          </>
          )}
          <Card className='mb-4'>
            <Card.Body>
            <Row>
              <Col sm>
              < h2>Enllaç visionat <small>Video per a la visualització del comité i jurat.</small></h2>
              </Col>
            </Row>
            <Form noValidate validated={ this.state.validated } id="formVideo" onSubmit={this.sendFormVideo.bind(this)}>
              <Row>
                <Col sm>
                  <Form.Group  className="mb-3" controlId="filmLink">
                    <Form.Label>Enllaç (Vimeo, Youtube o Google Drive)</Form.Label>
                    <Form.Control
                      required
                      type='text'
                      disabled={!U.isUserAdmin()}
                      placeholder='Ex: http://vimeo.com/exemple'
                      defaultValue={film.filmLink ? film.filmLink : '' }
                    >
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group  className="mb-3" controlId="filmPass">
                    <Form.Label>Contrassenya</Form.Label>
                    <Form.Control
                      required
                      type='text'
                      disabled={!U.isUserAdmin()}
                      placeholder='Ex: 1234'
                      defaultValue={film.filmPass ? film.filmPass : '' }
                    >
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                  </Form.Group>
                  <Button type='submit' disabled={!U.isUserAdmin()} className='btn btn-lg btn-success'><strong>Actualitzar dades</strong></Button>
                </Col>
                <Col sm>
                    { film.filmLinkType === 'vimeo' &&
                      <div style={{ margin: '20px 0 10px', width: '100%', border:'1px solid #CCC', padding: '0 0 50% 0', background: '#000', display: 'block', position: 'relative'}}>
                        <iframe title="vimeo" key={film.filmLinkId}  src={"https://player.vimeo.com/video/" + film.filmLinkId } style={{  width: '100%', height: '100%', position: 'absolute'}} allow="autoplay; fullscreen"></iframe>
                      </div>
                    }
                    { film.filmLinkType === 'youtube' &&
                      <div style={{ margin: '20px 0 10px', width: '100%',border:'1px solid #CCC', padding: '0 0 50% 0', background: '#000', display: 'block', position: 'relative'}}>
                        <iframe title="youtube" style={{  width: '100%', height: '100%', position: 'absolute'}}  src={"https://www.youtube.com/embed/" + film.filmLinkId } allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                      { film.eventLinkYouTube && !film.imageCover && ( 
                        <div>
                          <img src={"http://i3.ytimg.com/vi/" + film.filmLinkId + "/maxresdefault.jpg"} style={{ maxWidth: '100%' }} />
                          <img src={"http://i3.ytimg.com/vi/" + film.filmLinkId + "/hqdefault.jpg"} style={{ maxWidth: '100%' }} />
                          </div>
                        )}
                      </div>
                    }
                    { film.filmLinkType === 'drive' &&
                    <div style={{ margin: '20px 0 10px', width: '100%', border:'1px solid #CCC', padding: '0 0 50% 0', background: '#000', display: 'block', position: 'relative'}}>
                      <iframe title="drive" src={film.filmLink.replace('view?','preview?')} frameBorder="0" style={{  width: '100%', height: '100%', position: 'absolute'}} ></iframe>
                    </div>
                    }
                </Col>
              </Row>
            </Form>
            </Card.Body>
          </Card>
            </Container>
          </Tab>
          <Tab eventKey="contact" title="Contactes" className='pt-2'>
            <Container>
            <Form noValidate validated={ this.state.validated } id="formContactData" className='pb-4 mb-4' onSubmit={this.sendFormContactData.bind(this)}>
              {rolesVariants.map((modifier, key) => (
              <Card key={ 'contact-form-' + key} bg="light" className='mb-4'>
                <Card.Body>
              <Row>
                <Col>
                  <Form.Group controlId={'contactName' + modifier} className='mb-3'>
                    <Form.Label>Nom</Form.Label>
                    <Form.Control
                      type='text'
                      disabled={ key === 0 ? true : false}
                      defaultValue={film['contactName' + modifier] ? film['contactName' + modifier] : '' }
                      placeholder='Ex: Joan Roig'
                    />
                    <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group controlId={'contactRole' + modifier} className='mb-3'>
                    <Form.Label>Rol</Form.Label>
                    <Form.Control
                      type='text'
                      disabled={ key === 0 ? true : false}
                      defaultValue={film['contactRole' + modifier] ? film['contactRole' + modifier] : '' }
                      placeholder='Ex: Productor'
                    />
                    <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group controlId={'contactEmail' + modifier} className='mb-3'>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type='email'
                      disabled={ key === 0 ? true : false}
                      defaultValue={film['contactEmail' + modifier]  ? film['contactEmail' + modifier] : '' }
                      placeholder='Ex: Joan Roig'
                    />
                    <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                  </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group controlId={'contactPhone' + modifier} className='mb-3'>
                    <Form.Label>Telèfon</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='555 55 55 55'
                      disabled={ key === 0 ? true : false}
                      defaultValue={film['contactPhone' + modifier] ? film['contactPhone' + modifier] : '' }
                    />
                    <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                </Row>
                </Card.Body>
                </Card>
              ))}
                  <Button type='submit' className='btn btn-lg btn-success'><strong>Actualitzar contactes</strong></Button>
              </Form>
            </Container>
            </Tab>
        </Tabs>
        </>

        ) : (
          <Loading />
        )}
      </Container>
    );
  }
}
const Films = withFirebase(FilmsBase);

const condition = () => { return  U.isUserContentManager(); }

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(HomePage);