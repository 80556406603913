import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import _ from 'lodash';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from "../../constants/utilities";

import { PiCheckCircleFill, PiWarningDiamondFill } from 'react-icons/pi';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LuMailWarning, LuStar } from 'react-icons/lu';


let mailSignature = '\n🔴⚫ FIC-CAT ' + CONFIG.CURRENT_EDITION +'\n'
mailSignature += MESSAGES.FICCAT_DATE + '\n'
mailSignature += 'http://fic-cat.cat/' 

class FilmsListItem extends Component {
  constructor(props) {
    super(props);


    this.state = {
      filmType: props.filmType,
      firebase: props.firebase,
      film: props.film,
    }

    if (localStorage.getItem('currentEdition') === null ) {
      this.Edition = CONFIG.CURRENT_EDITION;
    } else {
      this.Edition = localStorage.getItem('currentEdition');
    } 

  }

  componentDidMount () {
    this.props.firebase.database().ref(CONFIG.USER_NOTES + U.authUser().id + '/' + this.state.film.uid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.setState({
          userFilm: snapshotUserFilm.val()
        });
      } else {
        this.setState({
          userFilm: {
            userFilmNote: '',
            userFilmRank: ''
          }
        });
      }
    })
  }

  resizedImage( imageUrl ) {
    if (imageUrl) {
      let path = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[0] + CONFIG.ACTIVE_EDITION + '_images%2Fthumbs%2F';
      let imageURlResponse = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[1];
      let token = imageUrl.split( 'token')[1];

      if (imageURlResponse !== undefined) {
       return path + imageURlResponse.split('.')[0] + '_800x800.jpeg' + '?alt=media&token' + token;
      }  else {
      return false
    } 
    } else {
      return false
    }
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  
  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }
  
  customizeMailBody(body, film, filmType) {
        
    let newBody = this.replaceAll(body, '$NAME', film.contactName)
    newBody = this.replaceAll(newBody, '$FILM_NAME', film.filmName)
    newBody = this.replaceAll(newBody, '$FILM_NAMUPPER', film.filmName.toUpperCase())
    newBody = this.replaceAll(newBody, '$FILM_TYPE', MESSAGES[filmType.replace('-','')])
    newBody = this.replaceAll(newBody, '$FILM_TYPUPPER', MESSAGES[filmType.replace('-','')].toUpperCase())
    newBody = this.replaceAll(newBody, '$FICCAT_EDITION', MESSAGES.FICCAT_EDITION)  
    newBody = this.replaceAll(newBody, '$FICCAT_DATE', MESSAGES.FICCAT_DATE)  
    newBody = this.replaceAll(newBody, '$FICCAT_YEAR', CONFIG.CURRENT_EDITION)
    newBody = this.replaceAll(newBody, '$FICCAT_PRESS', MESSAGES.FICCAT_PRESS)
    newBody = this.replaceAll(newBody, '$FICCAT_GALA', MESSAGES.FICCAT_GALA)
    if (film.filmSchoolLevel) {
     newBody = this.replaceAll(newBody, '$FICCAT_LEVELSCHOOL', film.filmSchoolLevel.replace('primaria','Primària').replace('secundaria','Secundària'))
    }
    return  encodeURIComponent(newBody)
  }

  materialMissing(film) {
    let body = ( ((!film.filmLinkDownload || !film.filmLinkTrailer))  ? "\nNecessitem abans del "+ CONFIG.MATERIAL_MOVIE_DATE.toLocaleDateString() +" els arxius per a la projecció i promoció:\n": '') 
    body += ( (!film.filmLinkDownload  ? "  - ❌ Pendent:": '  - ✅ Rebut:') + ' Enllaç de descàrrega per la projecció (Per enviar copies en format físic DCP poseu-vos en contacte amb nosaltres i us facilitarem una adreça o passarem a recollir-les.)\n') 
    body += ( (!film.filmLinkTrailer  ? "  - ❌ Pendent:": '  - ✅ Rebut:') + ' Enllaç tràiler en streaming\n') 
    body += ( (!film.isImageReady  ? "  - 🔵 Revisar:": '  - ✅ Revisat:') + ' Imatges pel catàleg i el web\n') 
    body += ( (film.acceptOnline !== false && (!film.acceptOnlineSigned || !film.acceptOnlineDownload))  ? "Necessitem abans del "+ CONFIG.MATERIAL_ONLINE_DATE.toLocaleDateString() +" la següent documentació i material si vols participar en el premi en línia (opcional)\n": '') 
    body += ( (!film.acceptOnlineSigned  ? "  - ❌ Pendent:": '  - ✅ Rebut:') + ' Cessió de drets en línia\n')
    body += ( (!film.acceptOnlineDownload  ? "  - ❌ Pendent:": '  - ✅ Rebut:') + ' Enllaç del fitxer per a 3CAT\n\n')  
    return body; 
  }

  DatetoHuman (date) {
    let newDate = new Date(date);
    return newDate.toLocaleDateString('ca-CA', {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  

  sendMailSelection(film, filmType) {
    const subject = '[S.O. ' + MESSAGES[filmType.replace('-','')] + ' '+ CONFIG.ACTIVE_EDITION +'] ' + film.filmName;
    const linkToMaterial = window.location.origin + ROUTES['SIGN_UP_FILMAKERS'] + '/' + filmType.toLowerCase() + '/' + this.Edition  + '/' + film.uid; 
    const separator = '____________________________________________________________\n\n';
    // const body = 'Please do not abuse "𝗯𝗼𝗹𝗱", "u̲n̲d̲e̲r̲l̲i̲n̲e̲", "𝘪𝘵𝘢𝘭𝘪𝘤" and other mathematical notations glyphs for ❝formatting❞.\nThanks! ' + linkToMaterial
    let body   = "Enhorabona $NAME,\n\n"
    body += "🥇 S.O. $FILM_TYPUPPER: $FILM_NAMUPPER\n"
    body += "En nom del Comitè de Selecció del Festival Internacional de Cinema en Català, és un plaer comunicar-vos que la vostra producció ha estat seleccionada per formar part de la Selecció Oficial $FILM_TYPE de la $FICCAT_EDITION edició del Festival $FICCAT_DATE.\n\n"
    body += "🔴 IMPORTANT: NO FEU PÚBLICA LA SELECCIÓ ENCARA.\n Tota la Selecció Oficial i el programa de projeccions es faran públics més endavant $FICCAT_PRESS.\n\n"
    body += "PREMIS I CATEGORIES EN LES QUALS PARTICIPEU:\n";
    if (filmType === 'CENTRES-EDUCATIUS') {
      body+= "  - Millor producció de Centres educatius ($FICCAT_LEVELSCHOOL).\n"
    } else {
      body += "  - Millor producció de $FILM_TYPE\n";
    }
    if (film.isTarragona) {
      body += "  - Millor producció de Comarques Tarragonines\n";
    }
    body += "  - Premi del públic\n"
    body += separator;
    body += 'ACCEDIU AL GESTOR DE MATERIALS PER FER-NOS ARRIBAR EL QUE ENS MANCA:\n'
    body += 'Per accedir heu de fer servir el correu i el telèfon de la persona de contacte que va omplir el formulari i fer servir el següent enllaç:\n'
    body += '  - 🔗 ' + linkToMaterial + '\n\n'
    body += 'MATERIAL QUE ENS MANCA:\n'
    body += this.materialMissing(film);
    body += "Dubtes respecte a l'enviament de materials o de l'aplicació de gestió de materials: programacio@fic-cat.cat\n"
    body += separator;
    body += "ASSISTÈNCIA A LA PROJECCIÓ + COL·LOQUI:\n"

    if (film.session) {
      body += "📅 " + film.session.name + ": " + this.DatetoHuman(film.session.date) + " a les "+ film.session.time + "\n📍 " + CONFIG.LOCATION[film.session.location.split("-")[0]].name + ": " + CONFIG.LOCATION[film.session.location.split("-")[0]].address + "\n🔗 " + CONFIG.LOCATION[film.session.location.split("-")[0]].url + "\n\n"
    }
    

    switch (filmType.toUpperCase()) {
      default:
      case "CENTRES-EDUCATIUS":
        body += "Ens agradaria molt comptar amb la vostra presència el dia de la projecció per poder fer la presentació de la producció, si us plau us preguem que confirmeu assistència posant-vos en contacte amb la vostra responsable de logística.\n"
      break;
      case "VIDEOCLIPS":
        body += "La projecció de Videoclips serà a la nit després de la pel·lícula d’inauguració. Serà una projecció molt festiva a l’aire lliure a la plaça on es troben els food-trucks i després tindrem sessió de DJ. Ens agradaria molt comptar amb la vostra presència el dia de la projecció per poder fer la presentació de la producció, si us plau us preguem que confirmeu assistència. Indiqueu els noms de les persones que assistiran, la vinculació amb la producció, e-mail i telèfon de contacte.\n"
      break;
      case "DOCUMENTALS":
      case "LLARGS":
        body += "Si us plau, us preguem que confirmeu assistència. Indiqueu els noms de les persones que assistiran, la vinculació amb la producció, e-mail i telèfon de contacte.\n"
        if (filmType==='LLARGS') {
          body += "\nD’altra banda, durant la projecció de la pel·lícula, ens agradaria convidar-vos a sopar mentre el públic veu la projecció. Per tot això, necessitarem saber, com més aviat millor la vostra assistencia així com si algú de vosaltres necessita allotjament per a aquella nit. Per motius de disponibilitat, us podem oferir aprox. fins a 4 allotjaments dobles + esmorzar\n"
        }
      break;
    }

    body += "\nASSISTÈNCIA A L'ENTREGA DE PREMIS:\n"
    body += "📅 Gala de Cloenda: $FICCAT_GALA\n📍 " + CONFIG.LOCATION[0].name + ": " + CONFIG.LOCATION[0].address + "\n\🔗 " + CONFIG.LOCATION[0].url + "\n\n"
    switch (filmType.toUpperCase()) {
      case "JOVE":
        body += "Màxim de 2 INVITACIONS amb seient reservat. Si venen més persones, hauran d’adquirir les entrades gratuites de públic general.\n"
      break;
      default:
        body += "Màxim de 4 INVITACIONS amb seient reservat. Si venen més persones, hauran d’adquirir les entrades gratuites de públic general.\n"
      break;
    }
    if (filmType.toUpperCase() !== 'CENTRES-EDUCATIUS') {

    body += "\nASSISTÈNCIA A LA RODA DE PREMSA + VERMUT:\n"
        body += "📅 Gala de Cloenda: $FICCAT_PRESS\n📍 " + CONFIG.LOCATION[4].name + ": " + CONFIG.LOCATION[4].address + "\n\🔗 " + CONFIG.LOCATION[4].url + "\n\n"
    body += "Acompanyeu-nos durant l'anunci de la programació completa del festival d’enguany i algunes sorpreses més. En finalitzar la presentació podrem gaudir d'un Vermut per gentilesa de Family Gins.\n"
  }
    body += "\nCOM CONFIRMAR ASSISTÈNCIA:\n"
    body += "Per confirmar assistència a qualsevol dels actes, si us plau contacteu amb la persona responsable de la vostra logística i indiqueu les dades requerides per cada esdeveniment:\n\n"
    if (film.session) {
      body += "👤" + CONFIG.LOGISTIC[film.session.logistic.split("-")[0]].name + "\n✉ " + CONFIG.LOGISTIC[film.session.logistic.split("-")[0]].mail + "\n📞 " + CONFIG.LOGISTIC[film.session.logistic.split("-")[0]].phone + "\n";
    }  else {
      body += "\n✉ logistica@fic-cat.cat\n"
    }
    body += separator;
    body += "De nou, aprofitem per felicitar-vos; ha estat una selecció veritablement competitiva i estem encantats de poder comptar amb la vostra producció.\n\n"
    body += "Salut i Cinema!\n"
    body += "L'Equip del FIC-CAT\n"
    body += mailSignature;
    return 'mailto:' + film.contactEmail + ';' + film['contactEmail-1'] + ';' + film['contactEmail-2'] + ';' + film['contactEmail-3'] + '?subject=' + subject + '&cc=programacio@fic-cat.cat&body=' + this.customizeMailBody(body, film, filmType) ;
  }
  

  sendMailMaterial(film, filmType) {
    const subject = '[REQUERIT] Material pendent: ' + film.filmName;
    const linkToMaterial = window.location.origin + ROUTES['SIGN_UP_FILMAKERS'] + '/' + filmType.toLowerCase() + '/' + this.Edition  + '/' + film.uid; 
    // const body = 'Please do not abuse "𝗯𝗼𝗹𝗱", "u̲n̲d̲e̲r̲l̲i̲n̲e̲", "𝘪𝘵𝘢𝘭𝘪𝘤" and other mathematical notations glyphs for ❝formatting❞.\nThanks! ' + linkToMaterial
    let body   = "Hola $NAME,\n\n"
    body      += "Aquest correu és un recordatori del fet que tenim pendent rebre material per la teva part per a la producció '$FILM_NAME'. \n"
    body += '\nNO  RESPONGUIS AQUEST CORREU... fes servir el següent enllaç:\n\n🔗 ' + linkToMaterial + '\n\n'
    body += 'MATERIAL QUE ENS MANCA:\n'
    body += this.materialMissing(film);
    body += mailSignature;
    return 'mailto:' + film.contactEmail + '?subject=' + subject + '&cc=programacio@fic-cat.cat&body=' + this.customizeMailBody(body, film, filmType);
  }

  render () {
    const { film, userFilm, filmType } = this.state
    const currentYear = new Date().getFullYear();
    return (
      <>
        { (( U.isUserContentManager() && film.isSelectable ) || (U.isUserProvider() && film.isOnline )) && (
        <Col className='table-list' xs={12}>
          <Row>
            <Col xs={12}  sm={12} lg={2}>
              <div className={ ((film.isInfoReady !== true || film.isImageReady !== true || film.isDownloaded !== true || film.isOnline !== true) ? 'bg-light' : 'bg-success') + ' --item p-2'}>
              <h5>PRODUCCIÓ</h5>
              <Row>
                <Col>
                  <strong>
                    { U.isUserAdmin() ? (
                      <a href={ROUTES['ITEM_EDIT'] + '/' + filmType + '/' + film.uid }>{film.filmName}</a>
                      ) : (
                      <a href={ROUTES['ITEM'] + '/' + filmType + '/' + film.uid }>{film.filmName}</a>
                    )}
                  </strong>
                </Col>
              </Row>
              </div>
            </Col>
            <Col>
              <div className={ ((film.isInfoReady && film.isImageReady) ? 'bg-success' : 'bg-light') + ' --item p-2'}>
                <h5>INFORMACIÓ</h5>
                <Row>
                  <Col>
                    {film.isInfoReady ? (<span className='text-success'><PiCheckCircleFill /></span> ) : (<span className='text-warning'><PiWarningDiamondFill /></span>)} Fitxa
                  </Col>
                  <Col>
                    {film.isImageReady ? (<span className='text-success'><PiCheckCircleFill /></span> ) : (<span className='text-warning'><PiWarningDiamondFill /></span>)} Imatges
                  </Col>
                  </Row>
              </div>
            </Col>
            {!U.isUserProvider() && (
            <Col>
              <div className={ ((film.isDownloaded) ? 'bg-success' : 'bg-light') + ' --item p-2'}>
                <h5>PROJECCIÓ</h5>
                <Row>
                <Col>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{film.session ?  film.session.name  + ':' : 'Aquesta producció no està'} <br />{film.session ? this.DatetoHuman(film.session.date) : 'associada a cap sessió'}</Tooltip>}
                      ><span>{film.session ? (<span className='text-success'><PiCheckCircleFill /></span> ) : (<span className='text-warning'><PiWarningDiamondFill /></span>)} Sessió</span>
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    {film.isDownloaded ? (<span className='text-success'><PiCheckCircleFill /></span> ) : (<span className='text-warning'><PiWarningDiamondFill /></span>)} Descarregat
                  </Col>
                  </Row>
              </div>
            </Col>
            )}
            <Col>
              <div className={ ((film.acceptOnlineSigned && film.acceptOnlineDownload && film.acceptOnlineUploaded) ? 'bg-success' : 'bg-light') + ' --item p-2'}>
                <h5>3CAT</h5>
                <Row>
                  <Col>
                  {film.acceptOnlineSigned ? (<span className='text-success'><PiCheckCircleFill /></span> ) : (<span className='text-warning'><PiWarningDiamondFill /></span>)} Drets
                </Col>
                <Col>
                  {film.acceptOnlineDownload ? (<span className='text-success'><PiCheckCircleFill /></span> ) : (<span className='text-warning'><PiWarningDiamondFill /></span>)} Fitxer
                </Col>
                <Col>
                  {film.acceptOnlineUploaded ? (<span className='text-success'><PiCheckCircleFill /></span> ) : (<span className='text-warning'><PiWarningDiamondFill /></span>)} Pujat
                </Col>
                </Row>
                </div>
            </Col>
            { U.isUserAdmin() && (
            <Col xs={12} sm={12} lg={2}>
                <div className={ ((film.isInfoReady !== true || film.isImageReady !== true  || film.isDownloaded !== true || film.isOnline !== true) ? 'bg-light' : 'bg-success') + ' --item text-center p-2'}>
                { (film.session &&  (film.isInfoReady !== true || film.isImageReady !== true  || film.isDownloaded !== true || film.isOnline !== true) )  && (
                  <ButtonGroup>
                  <a className='btn btn-primary text-white' href={ this.sendMailSelection(film, filmType.toUpperCase())} target='_blank' disabled={ (film.acceptOnlineSigned && film.acceptOnlineDownload && film.acceptOnlineUploaded && film.isDownloaded ) }> <LuStar /><small>Selecció</small></a>
                    <a className='btn btn-outline-primary' href={ this.sendMailMaterial(film, filmType.toUpperCase())} target='_blank' disabled={ (film.acceptOnlineSigned && film.acceptOnlineDownload && film.acceptOnlineUploaded && film.isDownloaded ) }> <LuMailWarning /><small>Recordatori</small></a>
                  </ButtonGroup>
                  )}
                  { !film.session && (
                    <p className='text-left'><span className='text-warning'><PiWarningDiamondFill /></span> <small>Sense sessió asociada no es poden enviar mails.</small></p>
                  )}
                  </div>
            </Col>
            )}
          </Row>
        </Col>
        )}
      </>
    )
  }
}

export default compose(
  withFirebase
)(FilmsListItem);