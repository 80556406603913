import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import { Link } from 'react-router-dom';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from "../../constants/utilities";

import FilmsListItemDefault from '../FilmsListItem';
import FilmsListItemMails from '../FilmsListContacts';


import FilmsListFilters from '../FilmsListFilters';
import Loading from '../Loading';
import { Container } from 'react-bootstrap';
import { PiWarningDiamondFill } from 'react-icons/pi';

let filmType = '';
let view = ''
let FilmsListItem = FilmsListItemDefault;


class FilmListPage extends Component {
  constructor(props) {
    super(props);

    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    view = props.match.params.view && U.isUserCommittee() ? props.match.params.view : 'normal';

    switch( props.match.params.view) {
      case 'email':
        FilmsListItem = U.isUserCommittee() ? FilmsListItemMails : FilmsListItemDefault;
      break;
      default:
        FilmsListItem = FilmsListItemDefault;
    }
    this.state = {
      type: null,
    }

    
  }
  render () {
    return (
        <Films />
    )
  }
}

const FilmList = ({ films }) => (
  <Row className='film-list mx-auto p-0' xs={1} md={view === 'normal' ? 3 : 1} lg={ view === 'normal' ? 4 : 1}>
    {films.map(film => (
      (U.isUserAdmin() || film.isSelectable) && (
      <Col key={film.uid}>
        <FilmsListItem film={film} filmType={filmType} />
      </Col>
        )
    ))}
  </Row>
);

class FilmsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      films: [],
      API: null,
    };

    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.shorts();
        this.filmTypeName = 'CURTS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.docs();
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.schools();
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.musics();
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.API = this.props.firebase.youngs();
        this.filmTypeName = 'JOVES';
      break;
      case 'llargs':
        default:
          this.state.API = this.props.firebase.films();
          this.filmTypeName = 'LLARGS';
        break;
    }
  }
  getFilmsByIsSelectable( films, value ) {
    let filmsSelectable = [];
    if (films !== null) {
      for ( var k = 0; k < films.length; k++) {
        if (films[k].isSelectable === value) {
          filmsSelectable.push(films[k])
        }
      }
    }
    return filmsSelectable;
  }


  UpdateFilmList() {
    this.setState({ loading: true });
    this.state.API.on('value', snapshot => {
      const filmObject = snapshot.val();
      if (filmObject) {
        const filmList = Object.keys(filmObject).map(key => ({
          ...filmObject[key],
          uid: key,
        }));
        // convert messages list from snapshot

        let filmListFiltered = {};
        switch(window.location.pathname.split('/')[3]) {
          case 'secundaria':
            filmListFiltered = _.filter( filmList, function(film) { return (film.filmSchoolLevel === 'secundaria' || film.filmSchoolLevel === 'batxillerat' ) } );
          break;
          case 'primaria':
            filmListFiltered = _.filter( filmList, function(film) { return (film.filmSchoolLevel === 'primaria' || film.filmSchoolLevel === 'infantil' ) } );
          break;
          default:
            filmListFiltered = filmList;
          break;
        }

        this.setState({
          films: filmListFiltered.reverse(),
          loading: false,
        });
      } else {
        this.setState({ films: null, loading: false });
      }
    });
  }
  componentDidMount() {
    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.type = 'JOVES';
      break;
      case 'llargs':
        default:
          this.state.type = 'LLARGS';
        break;
    }
    this.UpdateFilmList();
  }

  UNSAFE_componentWillReceiveProps() {
    this.UpdateFilmList();
  }
 
  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }
  render() {
    const { films, loading, type } = this.state;
    return (
      <div>
        {films ? (
          <React.Fragment>
            { loading ? (
              <Loading />
            ) : (
            <Container>
              <Row>
                <Col sm className='pb-4'>
                  <h2 className='my-2'>{ this.filmTypeName ?  MESSAGES[this.filmTypeName].charAt(0).toUpperCase() : ''}{ MESSAGES[this.filmTypeName] ? MESSAGES[this.filmTypeName].slice(1) : '' }<br />
                  <small>{ MESSAGES[this.filmTypeName + '_FORM_DESC'] }</small>
                  </h2>
                  <FilmsListFilters urlBase={ ROUTES.LIST_CENTRES } filmType={type} filters={['view','school'] } view={ view } />
                </Col>
                <Col sm>
                <Container className='card bg-light pt-1 mb-3 text-center'>
                  <Row>
                    <Col>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>Rebuts:</small></dt>
                        <dd className="h1 text-muted">{films ? films.length : '0'}</dd>
                      </dl>
                    </Col>
                    <Col>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>Acceptats:</small></dt>
                        <dd className="h1 text-success">{this.getFilmsByIsSelectable(films, true).length}</dd>
                      </dl>
                    </Col>
                    <Col>
                      <dl className='mb-0'>
                        <dt><small className='text-muted'>Rebutjats:</small></dt>
                        <dd className="h1 text-danger">{this.getFilmsByIsSelectable(films, false).length}</dd>
                      </dl>
                    </Col>
                    { U.isUserAdmin() &&(
                      <Col>
                        <dl className='mb-0'>
                          <dt><small className='text-muted'>Pendents:</small></dt>
                          <dd className="h1 text-warning">{this.getFilmsByIsSelectable(films, undefined).length}</dd>
                        </dl>
                      </Col>
                    )}
                  </Row>
                  </Container>
              </Col>
            </Row>
              <Row>
                <FilmList films={films} />
              </Row>
              </Container>
            )}
          </React.Fragment>
        ) : (
          <Alert variant="warning"> <PiWarningDiamondFill /> No hi ha produccions inscrites en aquesta categoría.</Alert>
        )}
      </div>
    );
  }
}
const Films = withFirebase(FilmsBase);

const condition = () => U.isUserCommittee();

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(FilmListPage);