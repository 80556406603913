import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import AppQRPage from '../QRApp';
import Tv3QRPage from '../QRTv3';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const Landing = (props) => (
  <Router>
      <div>
        <Route exact path={ROUTES.APP_QR} component={AppQRPage} />
        <Route exact path={ROUTES.TV3_QR} component={Tv3QRPage} />
      </div>
  </Router>
);

export default withAuthentication(Landing);
