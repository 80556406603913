import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Container, Row, Col, Card } from 'react-bootstrap';

const PasswordForgetPage = () => (
  <Container className="pt-4">
  <Row className="justify-content-md-center">
    <Col lg={6}>
  <h2 className="my-4">Recordar Contrasenya</h2>
      <Card bg="light" className='mb-4'>
        <Card.Body>
    <PasswordForgetForm />
    </Card.Body>
                </Card>
                </Col>
            </Row>
              </Container>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <div className='form-group'>
        <input
          name="email"
          className='form-control'
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Adreça d'email"
        />
        </div>
        <button className='btn btn-success' disabled={isInvalid} type="submit">
          Reiniciar contrasenya
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>T'has oblidat del password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
