import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import Loading from '../Loading';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import * as U from "../../constants/utilities";

class UserItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (!this.props.user) {
      this.setState({ loading: true });
    }

    this.props.firebase
      .user(this.props.match.params.id)
      .on('value', snapshot => {
        this.props.onSetUser(
          snapshot.val(),
          this.props.match.params.id,
        );

        this.setState({ loading: false });
      });
  }

  componentWillUnmount() {
    this.props.firebase.user(this.props.match.params.id).off();
  }



updateIs(e) {

  let { user } = this.props;

  if (user) {
    switch(e.target.type) {
      case 'checkbox':
        if (e.target.name === 'deactivated') {
          user[e.target.name] = JSON.parse(!e.target.checked)
        } else {
          user[e.target.name] = JSON.parse(e.target.checked)
        }
        this.props.firebase.user(this.props.match.params.id).set(user)

      break;
    }
  }

}

  onSendPasswordResetEmail = () => {
    if (confirm("Enviar correu per reiniciar contrassenya?")) {
      this.props.firebase.doPasswordReset(this.props.user.email);
    } else {
      return false;
    }
  };

  render() {
    const { user } = this.props;
    const { loading } = this.state;

    return (
      <Container>
        <h2 className='my-4'>Detalls de l'usuari <small>{this.props.match.params.id}</small></h2>
        {loading && <Loading />}

        {user && (
          <div>
            <Row>
              <Col sm>
              <dl>
              <dt>
                ID
              </dt>
              <dd>
                {this.props.match.params.id}
              </dd>
              <dt>
                E-mail:
              </dt>
              <dd>
              {user.email}
              </dd>
              <dt>
                Nom:
              </dt>
              <dd>
                {user.username}
              </dd>
              <dt>
                Contrasenya:
              </dt>
              <dd>
              <button
                className='btn btn-primary'
                type="button"
                onClick={this.onSendPasswordResetEmail}
              >
                Reiniciar contrasenya
              </button>
              </dd>
              <dt>
                Estat
              </dt>
              <dd>
                <Form id='statusForm' ref='statusForm' >  
                  <Form.Check
                    inline
                    label="Activat"
                    className={ user.deactivated !== true ? 'text-danger font-weight-bold' : 'text-muted'} 
                    id='deactivated'
                    name='deactivated'
                    onChange={this.updateIs.bind(this)}
                    type='switch'
                    defaultChecked={ user.deactivated !== true }
                  />
                </Form>
              </dd>
            </dl>
            { (user.roles && user.roles.FILMAKER) && (
            <dl>
              <dt>
                Distribuïdor?
              </dt>
              <dd>
                <Form id='distributorForm' ref='distributorForm' >  
                  <Form.Check
                    inline
                    label="Marcar com distribuïdor"
                    id='isDistributor'
                    name='isDistributor'
                    onChange={this.updateIs.bind(this)}
                    type='switch'
                    defaultChecked={ user.isDistributor === true }
                  />
                </Form>
              </dd>
            </dl>
          )}
              </Col>
              <Col sm>
                <Card bg='dark'>
                  <Card.Body>
                    {user.roles ? (
                      <>
                        <pre className='text-white'>ROLES: {JSON.stringify(user.roles, null, 2)}</pre>
                      </>
                    ) : (
                      <span>No hi ha cap</span>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            
            <span>
              
            </span>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: (state.userState.users || {})[props.match.params.id],
});

const mapDispatchToProps = dispatch => ({
  onSetUser: (user, uid) => dispatch({ type: 'USER_SET', user, uid }),
});

export default compose(
  withFirebase,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(UserItem);
