import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { Alert, Container } from 'react-bootstrap';
import { FaUnlockAlt } from 'react-icons/fa';

class SignOutPage extends Component {
    constructor(props) {
      super(props);
  
      //this.props.firebase.doSignOut()
  
    }

    render () {
        return (
            <Container>
            <Alert variant='info' className='my-4'>
            <FaUnlockAlt />
            <h5>Has sortit de l'àrea privada</h5>
            <p>Per tornar a iniciar sessió has de fer servir l'enllaç que vas fer servir per accedir.</p>
            <a href='/' className='btn btn-primary'>Anar a la pàgina d'inici</a>
            </Alert>
    
    
        </Container>
        )}
    }


export default withFirebase(SignOutPage);
