import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import { Alert, Button, Container } from 'react-bootstrap';
import { PiWarningDiamondFill } from 'react-icons/pi';
import { FaEnvelope } from 'react-icons/fa';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return needsEmailVerification(this.props.authUser) ? (
        <Container>
        <Alert variant='warning'>
          <PiWarningDiamondFill />
          {this.state.isSent ? (
            <p><strong>Confirmació de correu electrònic enviada a {this.props.authUser.email}:</strong> comprova els teus correus electrònics (carpeta de correu brossa  inclòs) i fes click a l'enllaç de confirmació . Actualitza aquesta pàgina un cop hagis confirmat el teu correu electrònic.</p>
          ) : (
            <p> <strong>Verifica el teu correu  {this.props.authUser.email}:</strong> Comprova els teus correus electrònics (carpeta de correu brossa inclòs) i fes click a l'enllaç de confirmació o envia't un altre correu electrònic de confirmació.
            </p>
          )}

          <Button
            type="button"
            className={!this.state.isSent ? 'btn btn-primary' : 'btn btn-success'}
            onClick={this.onSendEmailVerification}
            disabled={this.state.isSent}
          >
            {!this.state.isSent ? 'Enviar un altre correu de confirmació' : 'Enviat'}
          </Button>
        </Alert>
        </Container>
      ) : (
        <Component {...this.props} />
      );
    }
  }

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
  });

  return compose(
    withFirebase,
    connect(mapStateToProps),
  )(WithEmailVerification);
};

export default withEmailVerification;
