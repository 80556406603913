import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as CONFIG from '../../constants/config';
import * as U from '../../constants/utilities';


let filmType =''
let filmUid = ''
let year = ''

const withAuthorization = (condition, film) => Component => {
  class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);
      filmType = props.match.params.type ? props.match.params.type : "llargs";
      filmUid = props.match.params.uid ? props.match.params.uid : null;
      year = props.match.params.year ? props.match.params.year : undefined;

    }
    componentDidMount() {

      if (Component.name === 'FilmsSelectedStuff') {
        this.listener = this.props.firebase.onAuthUserListener(
          authUser => {
            if (!condition(U.authUser())) {
              this.props.history.push(ROUTES.SIGN_IN);
            }
          },
          () => {
            window.location = ( window.location.origin + ROUTES['SIGN_UP_FILMAKERS'] + '/' + filmType.toLowerCase() + '/' + year  + '/' + filmUid);
          },
        );
      } else {
        this.listener = this.props.firebase.onAuthUserListener(
          authUser => {
            if (!condition(U.authUser())) {
              this.props.history.push(ROUTES.SIGN_IN);
            }
          },
          () => {
            if (U.isUserAnonymous()) {
              this.props.history.push(ROUTES.SIGN_OUT)
            }
          },
        );
      }

    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return condition(this.props.authUser) ? (
        <Component {...this.props} />
      ) : null;
    }
  }

  const mapStateToProps = state => ({
    authUser: state.sessionState.authUser,
  });

  return compose(
    withRouter,
    withFirebase,
    connect(mapStateToProps),
  )(WithAuthorization);
};

export default withAuthorization;