import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import Loading from "../Loading";
import { Badge, Col, Container, Row } from "react-bootstrap";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (!this.props.users.length) {
      this.setState({ loading: true });
    }

    this.props.firebase.users().on("value", (snapshot) => {
      this.props.onSetUsers(snapshot.val());

      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users } = this.props;
    const { loading } = this.state;

    return (
      <Container>
        <h2 className="my-4">Usuaris<br /><small>Control i gestió d'accés </small></h2>
        {loading && <Loading />}
        {users.map((user) => (
          <div key={user.uid} className="table-list">
            <Row>
              <Col xs={1} lg={1}>
              <div className={ "--item  p-2 " + (user.deactivated === true ? 'bg-danger' : 'bg-success')}>

                { user.deactivated === true && (
                    <small className="text-white">DESACTIVAT</small>
                  )}
                  </div>
              </Col>
              <Col xs={11} lg={4}>
                <div className="--item bg-light p-2">

                  <Link to={`${ROUTES.ADMIN}/${user.uid}`}>
                    <strong>{user.email}</strong>
                  </Link>
                  <br/><small>{user.username}</small>
                  <br /><br />
                  <small className="text-truncate text-muted">{user.uid}</small>
                </div>
              </Col>
              <Col xs={12} lg={3}>
                <div className="--item bg-light p-2 text-truncate">
                  <h5>FILMS {user.isDistributor ? '(Distribuïdor)' : ''}</h5>
                  { (user.roles && user.roles.FILMAKER) && (
                    <ul>
                       {user.roles.FILMAKER.map((t,k) => <li><a key={k} href={ROUTES.ITEM_STUFF + '/' + t.type + '/' + t.year + '/' + t.uid}>{t.name}</a></li>)} 
                    </ul>
                  )}
                </div>
              </Col>
              <Col  xs={12} lg={4}>
                <div className="--item bg-light p-2">
                  <h5>ROLS:</h5>
                  {user.roles ? (
                    <>
                      <pre>{JSON.stringify(user.roles, null, 2)}</pre>
                    </>
                  ) : (
                    <span>No hi ha cap</span>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  users: Object.keys(state.userState.users || {}).map((key) => ({
    ...state.userState.users[key],
    uid: key,
  })),
});

const mapDispatchToProps = (dispatch) => ({
  onSetUsers: (users) => dispatch({ type: "USERS_SET", users }),
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(UserList);
