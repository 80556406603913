import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import _ from 'lodash';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';

import { Rating } from 'react-simple-star-rating'
import { Container } from 'react-bootstrap';
import FilmsBadges from '../FilmsBadges';

class FilmsTopItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filmType: props.filmType,
      firebase: props.firebase,
      film: props.film,
    }

  }

  componentDidMount () {
    this.props.firebase.database().ref(CONFIG.USER_NOTES + U.authUser().uid + '/' + this.state.film.uid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.setState({
          userFilm: snapshotUserFilm.val()
        });
      } else {
        this.setState({
          userFilm: {
            userFilmNote: '',
            userFilmRank: ''
          }
        });
      }
    })
  }

  resizedImage( imageUrl ) {
    if (imageUrl) {
      let path = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[0] + CONFIG.ACTIVE_EDITION + '_images%2Fthumbs%2F';
      let imageURlResponse = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[1];
      let token = imageUrl.split( 'token')[1];

      if (imageURlResponse !== undefined) {
       return path + imageURlResponse.split('.')[0] + '_800x800.jpeg' + '?alt=media&token' + token;
      }  else {
      return false
    }

    } else {
      return false
    }
  }

  render () {
    const { film, userFilm, filmType } = this.state
    return (
      <>
            { U.showTopFilms() && film.isSelectable && (
            <Card className={ film.userFilmScoreRank > 0 ? 'mb-3' : 'deactivated mb-3' }>
              <Card.Link href={ROUTES['ITEM'] + '/' + filmType + '/' + film.uid } style={{textDecoration: 'none'}}>
              <Row>
                <Col xs={6} lg={2}>
                  <Container className='p-2'>
                    <h3 className="d-sm-block d-lg-none mb-0" style={{ fontSize: '1.5em', color: '#dc3545',textDecorationColor:'#dc3545'  }}><strong>{ film.filmName }</strong></h3>
                    <Rating size='20' initialValue={ film.userFilmScoreRank ? Math.round(film.userFilmScoreRank) : 0 } readonly={true} /><br /> <span className='h1 text-muted'>{film.userFilmScoreRank ? film.userFilmScoreRank : ''}</span>
                    <small >
                      <pre className='text-muted pt-2'>
                      {film.userFilmPeopleNames}
                    </pre>
                    </small>
                  </Container>
                </Col>
                <Col className="d-none d-lg-block">
                <Card.Body style={{ textDecoration: 'none'}}>
                  <div className='pt-2 pb-4'>
                    <span style={{ fontSize: '1.5em', lineHeight: '0', color: '#dc3545',textDecorationColor:'#dc3545'  }}><strong>{ film.filmName }</strong></span><br />          
                    <small className='text-muted'> de <strong>{film.filmDirector}</strong> </small><br/>
                    <small className='text-muted'>
                      <strong>{film.filmYear} · {film.filmLengthFixed ? film.filmLengthFixed : film.filmLength} min · {film.filmGenre ? film.filmGenre : ''} · {film.filmCity}</strong>, {film.filmState} ({film.filmCountry.substring(0,3).toUpperCase()})
                    </small>
                  </div>

                  <FilmsBadges key={film.uid} film={film} filmType={filmType}/>
                </Card.Body>
                </Col>
                { (film.imageCover || film.imageFrame1) && (
                <Col xs={6} lg={3} className='p-4 text-center'>
                  <Card.Img src={this.resizedImage(film.imageCover ? film.imageCover : film.imageFrame1)} style={{ maxHeight: '200px', width: 'auto', maxWidth: '100%' } } />
                </Col>
                )}
              </Row>
              
              </Card.Link>

            { U.isUserAdmin() && (
              <>
                <a href={ROUTES['ITEM_EDIT'] + '/' + filmType + '/' + film.uid } className={'btn btn-sm text-white font-weight-bold ' + (film.isSelectable === undefined ? 'bg-warning' : (film.isSelectable === false ? 'bg-danger' : 'bg-success')) }>Administrar</a>
              </>
            )}
            </Card>
        )}
      </>
    )
  }
}

export default compose(
  withFirebase
)(FilmsTopItem);