import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as CONFIG from '../../constants/config';
import * as MESSAGES from '../../constants/messages';



const LandingTv3 = () => (
  <div className='container'>
      { window.location = 'http://tv3.cat/ficcat'}   
      <img alt="FIC-CAT" style={{ width: '100px', margin: '0 20px 10px 0' }} src="/img/logo.png" />
      <h2>Redireccionant...</h2>

  </div>
);

export default LandingTv3;
