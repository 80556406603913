import React from 'react';
import { withFirebase } from '../Firebase';
import { MdExitToApp } from 'react-icons/md';
import * as ROUTES from "../../constants/routes";

const SignOutButton = ({ firebase }) => (
  <a href={ROUTES.SIGN_OUT} className='h5' onClick={(e) => { e.preventDefault(); firebase.doSignOut() }}>
    <MdExitToApp />
  </a>
);

export default withFirebase(SignOutButton);
