import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import { Form, Card, Col, Container, Row } from 'react-bootstrap';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';


let orderSession = (sessionGroup) => {
  return _.sortBy(sessionGroup, "time");
};

class SessionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filmType: props.filmType,
      firebase: props.firebase,
      session: props.session ? props.session : {},
      variant: props.variant ? props.variant : 'default',
      selectedType: props.session && props.session.type ? props.session.type : '',
      selectedSession: props.session && props.session.type === 'replica' ? props.session.session : ''
    }


  }


  updateType(e) {


    this.setState({ selectedType: e.target.value})
 
  
  }



  updateSession(e) {

    this.setState({ selectedSession: {
      date: e.target.value
    }})
 
  
  }
  

  componentDidMount () {

    const scopeKeeper = this;

    this.props.firebase.sessions().on("value", (snapshot) => {
      const sessionObject = snapshot.val();
      if (sessionObject) {
        const sessionList = Object.keys(sessionObject).map((key) => ({
          ...sessionObject[key],
          uid: key,
        }));
        // convert messages list from snapshot

        let groupedSessions = _.map(_.groupBy(sessionList, "date"));

         scopeKeeper.setState({
          sessions: groupedSessions,
        });
      } else {
        scopeKeeper.setState({ sessions: null, loading: false });
      }
    });
  }


  render () {
    const { session, variant, sessions, selectedSession, selectedType } = this.state;
    return (
      <>
      { session && (
        <>
      <Row>
        <Col sm={12}>
          <Form.Group controlId='name' className='pb-3'>
            <Form.Label>Nom de la sessió</Form.Label>
            <Form.Control
              required
              type='text'
              disabled={!U.isUserAdmin()}
              defaultValue={ session.name}
              placeholder='Ex: Inauguració'
              ref={name => this.inputName = name}
            />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group controlId='date' className='pb-3'>
            <Form.Label>Dia</Form.Label>
            <Form.Control
              required
              type='date'
              disabled={ variant !== 'add' ? true : false }
              defaultValue={ session.date}
              ref={date => this.inputDate = date}
            />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group controlId='time' className='pb-3'>
            <Form.Label>Hora</Form.Label>
            <Form.Control
              required
              type='time'
              disabled={!U.isUserAdmin()}
              defaultValue={ session.time}
              ref={time => this.inputTime = time}
            />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={12}>
          <Form.Group controlId="location" className="mb-4" >
            <Form.Label>Ubicació</Form.Label>
            <Form.Control as="select"
              required
              defaultValue={ session.location}
              disabled={!U.isUserAdmin()}
              ref={location=> this.inputLocation = location}
            >
              <option value=''>Selecciona una opció...</option>
              { CONFIG.LOCATION.map((t,k) => <option key={k} value={k +'-' + t.name}>{t.name}</option>) } 
            </Form.Control>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={12}>
          <Form.Group controlId="type" className="mb-4" >
            <Form.Label>Tipus de sessió</Form.Label>
            <Form.Control as="select"
              required
              disabled={!U.isUserAdmin()}
              defaultValue={ session.type}
              onChange={this.updateType.bind(this)}
              ref={type=> this.inputType = type}
            >
              <option value=''>Selecciona una opció...</option>
              <option value='standard'>Llargs, curts i/o documentals</option>
              <option value='primaria'>Centres educatius primària</option>
              <option value='secundaria'>Centres educatius secundària</option>
              <option value='videoclips'>Videoclips</option>
              <option value='joves'>Concurs Jove</option>
              <option value='replica'>Replica</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
        </Col>
        </Row>
        { (sessions && selectedType === 'replica') && ( 
          <Row className='mb-4'>
          <Col>
            <Form.Group controlId="sessionDate">
            <Form.Label>Sessió que replica</Form.Label>
              <Form.Control as="select"
                required
                name="sessionDate"
                defaultValue={selectedSession ? selectedSession.date : ''}
                onChange={this.updateSession.bind(this)}
              >
                <option value=''>Selecciona una data...</option>
                  {sessions.map((sessionGroup, keyGroup) => (
                  <option value={sessionGroup[0].date} key={'sessionGroup-'+keyGroup}>
                    {sessionGroup[0].date}
                  </option>
                ))}
                  </Form.Control>
              </Form.Group>
              </Col>
              <Col>
              <Form.Group controlId="sessionTime">
              <Form.Label>&nbsp;</Form.Label>
                <Form.Control as="select"
                  required
                  defaultValue={selectedSession ? selectedSession.uid : ''}
                  name="sessionTime"
                >
                  <option value=''>Selecciona hora...</option>
                    {sessions.map((sessionGroup, keyGroup) => (
                      (selectedSession && sessionGroup[0].date === selectedSession.date) && (
                        orderSession(sessionGroup).map((session, key) => (
                          <option value={session.uid} key={'session-'+ key}>{session.time} ({session.name})</option>
                        ))
                      )
                    ))}
                  </Form.Control>
              </Form.Group>
          </Col>
          </Row>
        )}
        <Row>
        <Col lg={12}>
          <Form.Group controlId="logistic" className="mb-4" >
            <Form.Label>Responsable de logística</Form.Label>
            <Form.Control as="select"
              required
              disabled={!U.isUserAdmin()}
              defaultValue={ session.logistic}
              ref={logistic=> this.inputLogistic = logistic}
            >
              <option value=''>Selecciona una opció...</option>
              { CONFIG.LOGISTIC.map((t,k) => <option key={k} value={k +'-' + t.name}>{t.name}</option>) } 
            </Form.Control>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
        </Col>
        {U.isUserAdmin() && (
        <Col>
          <button type='submit' className='btn btn-lg btn-success mb-4'><strong>Guardar canvis</strong></button>
        </Col>
        )}
      </Row>
      </>
      )}
      </>
    )
    
  }
}

export default compose(
  withFirebase
)(SessionForm);