import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from "../../constants/utilities";

import { Rating } from 'react-simple-star-rating'


class FilmsListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filmType: props.filmType,
      firebase: props.firebase,
      film: props.film,
    }

  }

  componentDidMount () {
    this.props.firebase.database().ref(CONFIG.USER_NOTES + U.isUserAdmin() + '/' + this.state.film.uid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.setState({
          userFilm: snapshotUserFilm.val()
        });
      } else {
        this.setState({
          userFilm: {
            userFilmNote: '',
            userFilmRank: ''
          }
        });
      }
    })
  }



  resizedImage( imageUrl ) {
    if (imageUrl) {
      let path = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[0] + CONFIG.ACTIVE_EDITION + '_images%2Fthumbs%2F';
      let imageURlResponse = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[1];
      let token = imageUrl.split( 'token')[1];

      if (imageURlResponse !== undefined) {
       return path + imageURlResponse.split('.')[0] + '_800x800.jpeg' + '?alt=media&token' + token;
      }  else {
      return false
    }

    } else {
      return false
    }
  }

  render () {
    const { film, userFilm, filmType } = this.state
    const currentYear = new Date().getFullYear()
    return (
      <>
        { U.isUserContentManager() && film.isSelectable && (
          <li style={ ((film.filmTarragona !== undefined && film.filmTarragona === true) || ( film.filmTarragona === undefined  && film.filmState && film.filmState.toUpperCase().indexOf('TARRAGONA') !== -1 )) ? { backgroundColor: '#F2CDA2', color: '#000' } : { backgroundColor: '#FFF', color: '#000' }} >
            <b>{film.filmName}</b> de {film.filmDirector}. {film.producerCompany}.
          </li>
        )}
      </>
    )
  }
}

export default compose(
  withFirebase
)(FilmsListItem);