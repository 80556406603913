import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import FileUploader from 'react-firebase-file-uploader';
import * as CONFIG from '../../constants/config';
import { Card } from 'react-bootstrap';

class uploader extends Component {
    
  constructor(props) {
    super(props);
    this.onUploadSuccess = props.onUploadSuccess;
    this.state = {
        username: '',
        avatar: '',
        accept: props.accept ? props.accept : "image/*",
        isUploading: false,
        fileName: props.fileName ? props.fileName : 'Document',
        text: props.text ? props.text : 'Afegir imatge',
        minSize: props.minSize ? props.minSize : 0,
        maxSize: props.maxSize ? props.maxSize : 5000000,
        progress: 0,
        storageRef:props.storageRef ? props.storageRef : this.props.firebase.storage().ref(CONFIG.CURRENT_EDITION + '_images'),
        avatarURL: this.props.imageUrl ? this.props.imageUrl : '',
        showSizeError: false
    };
    
  }

    handleUploadStart = (event) => {
      this.state.eventSize = event.size;
      if (this.state.eventSize < this.state.maxSize && this.state.eventSize > this.state.minSize) {
        this.setState({isUploading: true, progress: 0, showSizeError: false})
      } else {
        this.setState({ showSizeError: true, progress: 0 })
      }
    };
    handleProgress = (progress) => this.setState({progress});
    handleUploadError = (error) => {
        this.setState({isUploading: false});
    }
    handleUploadSuccess = (filename) => {
      if (this.state.eventSize  < this.state.maxSize && this.state.eventSize > this.state.minSize) {
        this.setState({avatar: filename, progress: 100, isUploading: false});
        this.props.firebase.storage().ref(CONFIG.CURRENT_EDITION + '_images').child(filename).getDownloadURL().then(
            url => {
               this.setState({avatarURL: url});
               this.onUploadSuccess(url, this.props.imageField)
            }
         );
      }
    };

  render() {
    const { storageRef, text, accept } = this.state;
    return (
        <div>
        <form>

        {this.state.showSizeError &&
        <p className="text-danger">La imatge no compleix els requisitits de resolució. Si us plau adjusta la qualitat de l'imatge.</p>
        }
        {this.state.avatarURL && this.state.avatarURL.indexOf('.pdf') === -1 &&
            <img alt="this.state.avatarURL" src={this.state.avatarURL} style={{ maxWidth: '100%', padding: '10px', border: '1px solid #CCC', margin: '10px 0' }} />
        }

        <div className="form-group custom-input-file">
        {this.state.avatarURL && this.state.avatarURL.indexOf('.pdf') !== -1 &&
          <Card bg="white" className='mb-3 pb-4 text-center'>
            <a href={this.state.avatarURL} target='_blank'>
              <img src='/img/pdf-icon.png' alt="Veure PDF" style={{ maxWidth: '150px', margin: '10px 0' }} /><br/>
              <strong>{this.state.fileName}.pdf</strong>
            </a>
          </Card>
        }
        <div>
          {!this.state.isUploading &&
          <label className="btn btn-primary"><small><i className='icon font-weight-normal'>&#128206;</i></small>&nbsp;&nbsp;{text}</label>
          }
          {this.state.isUploading &&
          <label className="btn btn-primary">Progrés: {this.state.progress}</label>
          } 
          <FileUploader
              accept={accept}
              name="avatar"
              className="form-control-file"
              randomizeFilename
              storageRef={storageRef}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
          />
          </div>
        </div>
        </form>
        </div>);
  }
}

export default compose(
    withFirebase
  )(uploader);
