import React, { Component } from "react";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { withAuthorization, withEmailVerification } from "../Session";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Toast from 'react-bootstrap/Toast';

import FileUploader from "../FileUploader";
import * as CONFIG from "../../constants/config";
import * as ROUTES from "../../constants/routes";
import * as MESSAGES from "../../constants/messages";
import * as U from '../../constants/utilities';

import FilmsFormInfo from '../FilmsForm/info';
import FilmsFormCast from '../FilmsForm/cast';

import { getImageSize } from "react-image-size";
import { isEmpty } from "underscore";
import Loading from "../Loading";
import { Alert, ButtonGroup, Card, Container, ToastContainer } from "react-bootstrap";
import FilmsBadges from "../FilmsBadges";
import ModalImage from "react-modal-image";
import FilmsEditImages from "../FilmsEditImages";
import { FaFilePdf } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";
import { PiCheckCircleFill, PiWarningDiamondFill } from "react-icons/pi";

let filmType = "";
let filmUid = "";
let year = "";

class FilmsSelectedStuff extends Component {
  constructor(props) {
    super(props);

    this.state = {
      API: null,
      type: null,
      validated: null,
      sendFormStuffDataSaved: false
    };

    filmType = props.match.params.type ? props.match.params.type : "llargs";
    filmUid = props.match.params.uid ? props.match.params.uid : null;
    year = props.match.params.year ? props.match.params.year : null;

    if ( U.activeEdition() !== props.match.params.year  ) {
      U.setActiveEdition(year);
    }

    switch (filmType) {
      case "curts":
        this.state.type = "CURTS";
        this.state.API = this.props.firebase.short(filmUid);
        this.filmTypeName = "CURTS";
        break;
      case "documentals":
        this.state.API = this.props.firebase.doc(filmUid);
        this.state.type = "DOCS";
        this.filmTypeName = "DOCS";
        break;
      case "centres-educatius":
        this.state.API = this.props.firebase.school(filmUid);
        this.filmTypeName = "CENTRES";
        this.state.type = "CENTRES";
        break;
      case "videoclips":
        this.state.API = this.props.firebase.music(filmUid);
        this.state.type = "VIDEOCLIPS";
        this.filmTypeName = "VIDEOCLIPS";
        break;
      case "jove":
        this.state.API = this.props.firebase.young(filmUid);
        this.filmTypeName = "JOVES";
        this.state.type = "JOVES";

        break;
      case "llargs":
      default:
        this.state.API = this.props.firebase.film(filmUid);
        this.filmTypeName = "LLARGS";
        this.state.type = "LLARGS";
        break;
    }


  }


  componentDidMount() {

   
    this.props.firebase.user(U.authUser().uid)
    .once('value')
    .then(snapshot => {
      const dbUser = snapshot.val();
      this.setState({
        authUser: dbUser
      })

    })


    this.state.API.once("value", (snapshot) => {
      if (snapshot.val()) {
        // convert messages list from snapshot
        this.setState({
          film: snapshot.val(),
        });

        this.setState({
          isSelectable: this.state.film.isSelectable,
          isSelected: this.state.film.isSelected,
        });
      } else {
        this.setState({ film: null, });
      }
    });
  }

  mungeEmailAddress(s) {
    var i = s.indexOf("@");
    var startIndex = (i * 0.2) | 0;
    var endIndex = (i * 1) | 0; // i * .9 | 0
    return (
      s.slice(0, startIndex) +
      s.slice(startIndex, endIndex).replace(/./g, "*") +
      s.slice(endIndex)
    );
  }

  handleUploadSuccessRights = (url) => {
    if (url !== undefined) {
      let data = this.state.film;

      data.acceptOnlineSigned = url;

      this.setState({ film: data });

      if (data.filmName && data.acceptOnlineSigned) {
        this.updateOnlineTab(data);
        this.state.API.set(data);
      }
    }
  };

  updateIs(e) {
    let data = this.state.film;
    let modified = false;
    if (data.filmName) {
      switch (e.target.type) {
        case "button":
          e.preventDefault;
          data[e.target.name] = e.target.value;
          modified = true;
          break;
        case "checkbox":
          data[e.target.name] = JSON.parse(e.target.checked);
          modified = true;
          break;
        case "radio":
          data[e.target.name] = e.target.value;
          modified = true;
          break;
        case "select-one":
          data["isPremiereType"] = e.target.value;
          modified = true;
          break;
      }
      if (modified) {
        this.updateOnlineTab(data);
        this.setState({ film: data });
        this.state.API.set(data);
      }
    }
  }

  updateOnlineTab(data) {
    if (data) {
      if (!data.acceptOnlineSigned) {
        this.setState({ currentOnlineTab: "acceptOnlineSigned" });
      } else if (!data.acceptOnlineDownload) {
        this.setState({ currentOnlineTab: "acceptOnlineDownload" });
      } else if (data.acceptOnlineSigned && data.acceptOnlineDownload) {
        this.setState({ currentOnlineTab: "acceptOnlineReady" });
      }
      document.getElementById("OnlineTitle").scrollIntoView();
    }
  }

  sendFormOnlineData(e) {
    e.preventDefault();

    const form = e.currentTarget;

    this.setState({ validated: true });

    // GET ORIGINAL DATA
    let data = this.state.film;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      // OVERWRITE THE VALUES WITH THE ONES IN THE FORM
      data.acceptOnlineDownload = form.elements["acceptOnlineDownload"].value;
      data.acceptOnlineDownloadType =
        form.elements["acceptOnlineDownloadType"].value;

      // STORE DATA
      this.updateOnlineTab(data);
      this.state.API.set(data);
      this.setState({ success: true });
    }
  }

  sendFormStuffData(e) {
    e.preventDefault();

    const form = e.currentTarget;

    this.setState({ validated: true });

    // GET ORIGINAL DATA
    let data = this.state.film;
    let params = {}

    if (form.checkValidity() === false) {
      document.documentElement.scrollTop = 0;
      e.stopPropagation();
    } else {
      // OVERWRITE THE VALUES WITH THE ONES IN THE FORM

      params = {
        // FILM INFO
        filmName: form.elements["filmName"] ? form.elements["filmName"].value : 'Sense títol',
        filmBand: form.elements["filmBand"] ? form.elements["filmBand"].value : (data.filmBand ? data.filmBand : null ),
        filmSynopsis: form.elements["filmSynopsis"] ? form.elements["filmSynopsis"].value : (data.filmSynopsis ? data.filmSynopsis : null ),
        filmLength: form.elements["filmLength"] ? form.elements["filmLength"].value : (data.filmLength ? data.filmLength : null ),
        filmYear: form.elements["filmYear"] ? form.elements["filmYear"].value : (data.filmYear ? data.filmYear : null ),
        filmGenre: form.elements["filmGenre"] ? form.elements["filmGenre"].value : (data.filmGenre ? data.filmGenre : null ),
        filmCountry: form.elements["filmCountry"] ? form.elements["filmCountry"].value : (data.filmCountry ? data.filmCountry : null ),
        filmState: form.elements["filmState"] ? form.elements["filmState"] .value : (data.filmState ? data.filmState : null ),
        filmCity: form.elements["filmCity"] ? form.elements["filmCity"].value : (data.filmCity ? data.filmCity : null ),
        filmFirst: form.elements["filmFirst"] ? form.elements["filmFirst"].value : (data.filmFirst ? data.filmFirst : null ),
        isFirst: form.elements["filmFirst"] ? (form.elements["filmFirst"].value === 'true' ? true : false) : (data.filmFirst ? data.filmFirst : null),
        filmPremiere: form.elements["filmPremiere"] ? (form.elements["filmPremiere"].value === 'true' ? true : false ) : (data.filmPremiere ? data.filmPremiere : null),
        isPremiere: form.elements["filmPremiere"] ? (form.elements["filmPremiere"].value === 'Estrena' ? true : false ) : (data.filmPremiere ? data.filmPremiere : null),
        filmFestivals: form.elements["filmFestivals"] ? form.elements["filmFestivals"].value : data.filmFestivals,
        filmLangCAT: form.elements["filmLangCAT"] ? form.elements["filmLangCAT"].checked : (data.filmLangCAT ? data.filmLangCAT : false ),
        filmLangES: form.elements["filmLangES"] ? form.elements["filmLangES"].checked : (data.filmLangES ? data.filmLangES : false ),
        filmLangEN: form.elements["filmLangEN"] ? form.elements["filmLangEN"].checked : (data.filmLangEN ? data.filmLangEN : false ),
        filmLangOT: form.elements["filmLangOT"] ? form.elements["filmLangOT"].checked : (data.filmLangOT ? data.filmLangOT : false ),
        filmTarragonaStaff: form.elements["filmTarragonaStaff"] ? form.elements["filmTarragonaStaff"].checked : (data.filmTarragonaStaff ? data.filmTarragonaStaff : false ),
        filmTarragonaLocation: form.elements["filmTarragonaLocation"] ? form.elements["filmTarragonaLocation"].checked : (data.filmTarragonaLocation ? data.filmTarragonaLocation : false ),
        
        // CAST
        filmSchoolTutor: form.elements["filmSchoolTutor"] ? form.elements["filmSchoolTutor"].value : (data.filmSchoolTutor ? data.filmSchoolTutor : null ),                
        filmDirector: form.elements["filmDirector"] ? form.elements["filmDirector"].value : (data.filmDirector ? data.filmDirector : null ),
        filmDirectorGenre: form.elements["filmDirectorGenre"] ? form.elements["filmDirectorGenre"].value : (data.filmDirectorGenre ? data.filmDirectorGenre : null ),
        producerName: form.elements["producerName"] ? form.elements["producerName"].value : (data.producerName ? data.producerName : null ),
        producerGenre: form.elements["producerGenre"] ? form.elements["producerGenre"].value : (data.producerGenre ? data.producerGenre : null ),
        producerCompany: form.elements["producerCompany"] ? form.elements["producerCompany"].value : (data.producerCompany ? data.producerCompany : null ),
        producerSite: form.elements["producerSite"] ? form.elements["producerSite"].value : (data.producerSite ? data.producerSite : null ),
        writerName: form.elements["writerName"] ? form.elements["writerName"].value : (data.writerName ? data.writerName : null ),
        writerGenre: form.elements["writerGenre"] ? form.elements["writerGenre"].value : (data.writerGenre ? data.writerGenre : null ),
        filmActoring: form.elements["filmActoring"] ? form.elements["filmActoring"].value : (data.filmActoring ? data.filmActoring : null ),

        // FILM DOWNLOAD
        filmLinkDownload: form.elements["filmLinkDownload"] ? form.elements["filmLinkDownload"].value : (data.filmLinkDownload ? data.filmLinkDownload : null),
        filmLinkTrailer: form.elements["filmLinkTrailer"] ? form.elements["filmLinkTrailer"].value : (data.filmLinkTrailer ? data.filmLinkTrailer : null),
      }

      params.filmLanguage = ((params.filmLangCAT ? 'Català' : '') + ( params.filmLangES ? ', Castellà' : '') + (params.filmLangEN ? ', Anglès' : '') + (params.filmLangOT ? ', Altres.' : ''))
      params.filmTarragona = (params.filmTarragonaStaft ? true : false) + (params.filmTarragonaLocation ? true : false);
      params.isTarragona = (params.filmTarragonaStaff && params.filmTarragonaLocation ) ? ( ( (params.filmTarragonaStaff ) + (params.filmTarragonaLocation) ) === 2 ? true : false ) : false ;
      
      const dataToSend = { ...data, ...params };

      // STORE DATA

      this.state.API.set(dataToSend);
      this.setState({ success: true, sendFormStuffDataSaved: true, sendFormStuffDataSavedDate: Date.now() });
      setTimeout( () => this.setState({ sendFormStuffDataSaved: false }), 5000 )

    }
  }

  render() {
    const { film, currentOnlineTab, authUser, sendFormStuffDataSaved, sendFormStuffDataSavedDate} = this.state;
    return (
      <>
       <ToastContainer>
        <Toast bg="success" show={sendFormStuffDataSaved} onClose={() => this.setState({ sendFormStuffDataSaved: false })}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto"><PiCheckCircleFill className='icon text-success' /> Dades guardades</strong>
            <small>{U.datetoHuman(sendFormStuffDataSavedDate, 'medium')}</small>
          </Toast.Header>
        </Toast>
        </ToastContainer>
      { film ? (
      <Container>
       
        {(( film.isSelected && filmType !== 'jove') || ( filmType === 'jove' && Date.now() > CONFIG.MATERIAL_YOUNG_START && Date.now() < CONFIG.MATERIAL_YOUNG_END ) ) ? (
          <>
          { (authUser && ((authUser.roles.FILMAKER && authUser.roles.FILMAKER.map(e => e.uid).indexOf(filmUid) !== -1) || authUser.roles.ADMIN)) ? (
              <Container className="p-0 m-0">
                <div className="pb-4">
                  { filmType !== 'jove' ? (
                  <>
                  <h1>{film.filmName}</h1>
                  <p>
                    En nom del Comitè, és un plaer comunicar-vos que{" "}
                    <strong>la vostra producció ha estat seleccionada</strong>{" "}
                    per formar part de la Selecció Oficial el FIC-CAT{" "}
                    {CONFIG.CURRENT_EDITION}. A continuació detallem les accions
                    que requerim per poder rebre els materials necesaris.
                  </p>
                  </>
                  ) : (
                    <>
                    <h1>Concurs Jove</h1>
                    <Alert variant="warning">
                      <PiWarningDiamondFill />
                      <strong>Aquest formulari romandrà obert fins {U.datetoHuman(CONFIG.MATERIAL_YOUNG_END, 'large')}.</strong>  <br /> Per a qualsevol incidencia contacta amb nosaltres a <a href="mailto:programacio@fic-cat.cat">programacio@fic-cat.cat.</a>
                    </Alert>
                    </>
                  )}
                </div>
                {filmType !== 'jove' && (
                <>
                  <h2 id="OnlineTitle" className="mb-4 pb-2">
                    Premi en línea{" "}
                    <small>
                      <strong className="text-info">
                        El {CONFIG.MATERIAL_ONLINE_DATE.toLocaleDateString()}{" "}
                        finalitza el termini
                      </strong>{" "}
                      per presentar la cessió de drets i l'arxiu per 3CAT.
                    </small>
                  </h2>
                  {CONFIG.MATERIAL_ONLINE_DATE - Date.now() < 0 ? (
                    <Alert variant="info">
                      <FaInfoCircle /> Ja hem tancat la recepció de candidatures
                      per al premi del públic. En cas de que hi hagi qualsevol
                      problema contacta amb:{" "}
                      <a href="mailto:programacio@fic-cat.cat">
                        programacio@fic-cat.cat
                      </a>
                    </Alert>
                  ) : (
                    <>
                      {film.isOnline ? (
                        <Alert variant="success mb-4">
                          {" "}
                          <PiCheckCircleFill />{" "}
                          <strong>
                            Ja hem verificat que la cessió de drets es correcte i
                            tenim l'arxiu per 3CAT.
                          </strong>{" "}
                          Per qualsevol problema pots posar-te en contacte amb
                          nosaltres a{" "}
                          <a href="mailto:programacio@fic-cat.cat">
                            programacio@fic-cat.cat
                          </a>
                        </Alert>
                      ) : (
                        <Tabs
                          defaultActiveKey={
                            !film.acceptOnlineSigned
                              ? "acceptOnlineSigned"
                              : !film.acceptOnlineDownload
                              ? "acceptOnlineDownload"
                              : "acceptOnlineReady"
                          }
                          activeKey={currentOnlineTab}
                          onSelect={(key) =>
                            this.setState({ currentOnlineTab: key })
                          }
                          id="OnlineSteps"
                          className="mb-3"
                        >
                          <Tab
                            eventKey="acceptOnlineSigned"
                            title={
                              (film.acceptOnlineSigned ? "\u2705" : "") +
                              " 1. Signar Cessió de drets"
                            }
                          >
                            <Row className="pb-4">
                              <Col className="pr-lg-4">
                                {film.acceptOnline !== "false" ? (
                                  <div>
                                    {film.acceptOnlineSigned && (
                                      <Alert variant="success">
                                        {" "}
                                        <PiCheckCircleFill />
                                        <strong>
                                          Gràcies per pujar el document de cessió
                                          de drets.
                                        </strong>{" "}
                                        Revisarem si es correcte i si hi ha
                                        qualsevol incidencia ens posarem en
                                        contacte amb tu.
                                      </Alert>
                                    )}
                                    <p>
                                      Després de l’èxit de les últimes edicions,
                                      enguany repetim el format híbrid i les
                                      produccions que ho desitgin podran també
                                      estar{" "}
                                      <strong>
                                        disponibles durant la setmana del festival
                                        a un espai habilitat al 3CAT
                                      </strong>
                                      .
                                    </p>
                                    <p>
                                      Amb això volem donar més visibilitat a les
                                      produccions seleccionades, que també{" "}
                                      <strong>
                                        opten al premi del públic en línia
                                      </strong>{" "}
                                      (atorgat a la producció més viral).
                                    </p>
                                    <p>
                                      Per participar-hi (opcional), heu de
                                      descarregar el següent formulari i afegir-ho
                                      amb el botó "afegir document".{" "}
                                    </p>
                                    <ButtonGroup className="mb-4">
                                      <a
                                        className="btn btn-primary"
                                        href={
                                          "/pdf/bases_online_" +
                                          CONFIG.CURRENT_EDITION +
                                          ".pdf"
                                        }
                                        target="_blank"
                                      >
                                        <FaFilePdf /> Descarregar Cessió de drets
                                        en línia
                                      </a>
                                      {!film.acceptOnlineSigned && (
                                        <Button
                                          variant="outline-dark"
                                          onClick={this.updateIs.bind(this)}
                                          name="acceptOnline"
                                          value={false}
                                        >
                                          No, no vull participar
                                        </Button>
                                      )}
                                    </ButtonGroup>
                                  </div>
                                ) : (
                                  <div>
                                    <Alert variant="info">
                                      <FaInfoCircle />{" "}
                                      <p>
                                        Quan vas fer l'inscripció vas marcar que
                                        no estaves interessat/da en participar en
                                        el premi en línea
                                      </p>
                                      {CONFIG.MATERIAL_ONLINE_DATE - Date.now() >
                                        0 && (
                                        <div>
                                          <p>
                                            En qualsevol cas,{" "}
                                            <strong>
                                              si canvies d'opinió abans del{" "}
                                              {CONFIG.MATERIAL_ONLINE_DATE.toLocaleDateString()}
                                            </strong>{" "}
                                            pots fer click al següent botó i
                                            participar.
                                          </p>
                                          <Button
                                            variant="outline-dark"
                                            onClick={this.updateIs.bind(this)}
                                            name="acceptOnline"
                                            value={true}
                                          >
                                            Si, si vull participar
                                          </Button>
                                        </div>
                                      )}
                                    </Alert>
                                  </div>
                                )}
                              </Col>
                              {film.acceptOnline !== "false" && (
                                <Col lg={4}>
                                  <Card bg="light">
                                    <Card.Body>
                                      <Form>
                                        <Form.Label className="pb-2">
                                          <strong>
                                            {" "}
                                            Document cessió de drets signat
                                          </strong>
                                          <br />
                                          <small>
                                            (Format PDF o imatge. Mínim 10KB,
                                            máxim 2MB.
                                          </small>
                                        </Form.Label>
                                      </Form>

                                      <FileUploader
                                        hidden
                                        accept="image/*,.pdf"
                                        maxSize={2000000}
                                        minSize={10}
                                        fileName={
                                          "Cessió drets online " + film.filmName
                                        }
                                        imageUrl={film.acceptOnlineSigned}
                                        text="Afegir document"
                                        onUploadSuccess={
                                          this.handleUploadSuccessRights
                                        }
                                      ></FileUploader>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              )}
                            </Row>
                          </Tab>
                          <Tab
                            eventKey="acceptOnlineDownload"
                            title={
                              (film.acceptOnlineDownload ? "\u2705" : "") +
                              " 2. Arxiu per 3CAT"
                            }
                          >
                            <h4 className="my-4 text-center">
                              Si us plau tria una opció:
                            </h4>
                            <Form
                              noValidate
                              validated={this.state.validated}
                              id="formOnlineData"
                              onSubmit={this.sendFormOnlineData.bind(this)}
                            >
                              <Row className="online-options">
                                <Col sm>
                                  <Card
                                    bg={
                                      film.acceptOnlineDownloadType === "mxf"
                                        ? "success"
                                        : "light"
                                    }
                                    className="mb-4"
                                  >
                                    <Card.Body>
                                      <Form.Check>
                                        <Form.Label htmlFor="acceptOnlineDownloadType">
                                          <h5 style={{ paddingLeft: "20px" }}>
                                            {" "}
                                            <Form.Check.Input
                                              id="acceptOnlineDownloadType"
                                              name="acceptOnlineDownloadType"
                                              type="radio"
                                              value="mxf"
                                              onChange={this.updateIs.bind(this)}
                                              defaultChecked={
                                                film.acceptOnlineDownloadType ===
                                                "mxf"
                                              }
                                            />{" "}
                                            &nbsp;OPCIÓ 1: Proveïr vídeo per 3CAT{" "}
                                            <small className="text-muted">
                                              (Recomanat)
                                            </small>
                                          </h5>
                                          <Alert variant="info">
                                            <FaInfoCircle /> 3CAT requereix que
                                            els vídeos estiguin en un format
                                            concret. Creiem que{" "}
                                            <strong>
                                              ningú millor que vosaltres per
                                              garantir que la qualitat no es veu
                                              afectada
                                            </strong>{" "}
                                            en reconversions. Per això necessitem
                                            un fitxer amb els següents parametres.{" "}
                                            <a
                                              href="/pdf/codecs_3CAT.pdf"
                                              target="_blank"
                                            >
                                              Més informació
                                            </a>
                                          </Alert>
                                          <Card>
                                            <Card.Body>
                                              <small className="text-muted">
                                                Format: MXF
                                                <br /> Còdec de video: AVC-Intra
                                                100
                                                <br /> Tamany: Full HD 1920x1080
                                                (Es requerit encaixar-ho amb
                                                franjes negres)
                                                <br /> Velocitat fotogrames: 25
                                                <br /> Ordre de camps: Progressiu
                                                <br />
                                                Color space: REC. 709
                                                <br />
                                                Audio:2 Canals stereo 48000 Hz 16
                                                bit
                                              </small>
                                            </Card.Body>
                                          </Card>
                                        </Form.Label>
                                      </Form.Check>
                                    </Card.Body>
                                  </Card>
                                </Col>
                                <Col sm>
                                  <Card
                                    bg={
                                      film.acceptOnlineDownloadType === "other"
                                        ? "success"
                                        : "light"
                                    }
                                    className="mb-4"
                                  >
                                    <Card.Body>
                                      <Form.Check>
                                        <Form.Label htmlFor="acceptOnlineDownloadType2">
                                          <h5 style={{ paddingLeft: "20px" }}>
                                            {" "}
                                            <Form.Check.Input
                                              id="acceptOnlineDownloadType2"
                                              name="acceptOnlineDownloadType"
                                              type="radio"
                                              value="other"
                                              onChange={this.updateIs.bind(this)}
                                              defaultChecked={
                                                film.acceptOnlineDownloadType ===
                                                "other"
                                              }
                                            />{" "}
                                            &nbsp;OPCIÓ 2: Delegar la conversió
                                            del vídeo
                                          </h5>
                                          <p>
                                            En cas de que no tinguis els mitjans
                                            nostaltres ens podem encarregar de la
                                            conversió, per això necessitarem la
                                            teva producció en un format digital en
                                            la máxima qualitat possible.{" "}
                                          </p>
                                          <p>
                                            <small className="text-muted">
                                              Nota: Pots fer servir el mateix
                                              enllaç de descarrega de l'arxiu de
                                              projecció sempre i quan compleixi
                                              els requisits. Per exemple, el
                                              format DCP no ens serveix per fer la
                                              conversió però si els formats MP4 o
                                              Mov.
                                            </small>
                                          </p>
                                        </Form.Label>
                                      </Form.Check>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Card
                                    bg="light"
                                    className="online-options mt-4 mb-4"
                                  >
                                    <Card.Body>
                                      <Form.Group
                                        controlId="acceptOnlineDownload"
                                        className="mt-3 mb-3"
                                      >
                                        <Form.Label>
                                          <strong>Enllaç de descàrrega</strong> de
                                          l'arxiu{" "}
                                          <strong className="text-info">
                                            {film.acceptOnlineDownloadType ===
                                            "mxf"
                                              ? "(Format MXF)"
                                              : "(Format MP4 o MOV)"}
                                          </strong>{" "}
                                          per 3CAT
                                          <br />
                                        </Form.Label>
                                        <Form.Control
                                          required
                                          type="url"
                                          defaultValue={
                                            film.acceptOnlineDownload
                                              ? film.acceptOnlineDownload
                                              : ""
                                          }
                                          placeholder="Ex: http://drive.google.com/1234"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {MESSAGES.FIELD_REQUIRED}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                              <Button
                                type="submit"
                                className="btn btn-lg btn-success mb-4"
                              >
                                <strong>Guardar canvis</strong>
                              </Button>
                            </Form>
                          </Tab>
                          <Tab
                            eventKey="acceptOnlineReady"
                            title={
                              (film.acceptOnlineSigned &&
                              film.acceptOnlineDownload
                                ? "\u2705"
                                : "") + " 3. Finalitzat"
                            }
                            disabled={
                              !film.acceptOnlineSigned
                                ? true
                                : !film.acceptOnlineDownload
                                ? true
                                : false
                            }
                          >
                            <Alert variant="success mb-4">
                              {" "}
                              <PiCheckCircleFill />
                              <strong>
                                Gràcies per pujar el document de cessió de drets i
                                l'arxiu per 3CAT.
                              </strong>{" "}
                              Revisarem si es correcte i si hi ha qualsevol
                              incidencia ens posarem en contacte amb tu.
                            </Alert>
                          </Tab>
                        </Tabs>
                      )}
                      </>
                      )}
                  <hr className="mb-4 pb-4" />
                  </>
                )}
                 <h2 className="mt-4 mb-4 pb-2">
                  Imatges pel catàleg{" "}
                  { filmType !== 'jove' && (
                  <small>
                    <strong className="text-info">
                      El {CONFIG.MATERIAL_MOVIE_DATE.toLocaleDateString()}{" "}
                      finalitza el termini
                    </strong>{" "}
                    per fer entrega les imatges per al catàleg i el web.
                  </small>
                  )}
                </h2>
                {CONFIG.MATERIAL_MOVIE_DATE - Date.now() < 0 ? (
                  <Alert variant="info">
                    <FaInfoCircle /> Ja hem tancat la recepció d'arxius de
                    projeccions. En cas de que hi hagi qualsevol problema
                    contacta amb:{" "}
                    <a href="mailto:programacio@fic-cat.cat">
                      programacio@fic-cat.cat
                    </a>
                  </Alert>
                ) : (
                  <>
                    {film.isImageReady ? (
                      <Alert variant="success mb-4">
                        {" "}
                        <PiCheckCircleFill />{" "}
                        <strong>
                          Ja hem verificat que les imatges son correctes.
                        </strong>{" "}
                        Per qualsevol problema pots posar-te en contacte amb
                        nosaltres a{" "}
                        <a href="mailto:programacio@fic-cat.cat">
                          programacio@fic-cat.cat
                        </a>
                      </Alert>
                    ) : (
                      <Row>
                        { filmType !== 'jove' && (
                        <Col sm={6} lg>
                          <FilmsEditImages
                            filmUid={filmUid}
                            film={film}
                            filmType={filmType}
                            imageName="imageCover"
                          />
                        </Col>
                        )}
                        <Col sm={6} lg>
                          <FilmsEditImages
                            filmUid={filmUid}
                            film={film}
                            filmType={filmType}
                            imageName="imageFrame1"
                          />
                        </Col>
                        <Col sm={6} lg>
                          <FilmsEditImages
                            filmUid={filmUid}
                            film={film}
                            filmType={filmType}
                            imageName="imageFrame2"
                          />
                        </Col>
                        <Col sm={6} lg>
                          <FilmsEditImages
                            filmUid={filmUid}
                            film={film}
                            filmType={filmType}
                            imageName="imageFrame3"
                          />
                        </Col>
                      </Row>
                    )}
                    {filmType !== 'jove' && (
                    <hr className="mb-4 pb-4" />
                  )}
                  </>
                )}
                { (CONFIG.MATERIAL_MOVIE_DATE - Date.now() > 0 && !film.isDownloaded) ? (
                  <Form
                    noValidate
                    validated={this.state.validated}
                    id="formBasicData"
                    className="mb-4 pb-4"
                    onSubmit={this.sendFormStuffData.bind(this)}
                  >
                  {filmType === 'jove' && (
                  <Row className="mb-4">
                      <FilmsFormInfo type={filmType} formStructure={CONFIG.JOVES_FILM} film={film} />
                      <FilmsFormCast type={filmType} formStructure={CONFIG.JOVES_FILM} film={film} />
                  </Row>
                  )}
                  <Row>
                    <Col sm>
                      <h2 className="mb-4 pb-2">
                        Descàrrega d'arxius i promoció{" "}
                        {filmType !== 'jove' && (
                        <small>
                          <strong className="text-info">
                            El { U.datetoHuman(CONFIG.MATERIAL_MOVIE_DATE)}{" "}
                            finalitza el termini
                          </strong>{" "}
                          per fer entrega l'arxiu de projecció.
                        </small>
                        )}
                      </h2>
                      {(film.filmLinkDownload && filmType !== 'jove') && (
                        <Alert variant="success">
                          {" "}
                          <PiCheckCircleFill />
                          <strong>
                            Gràcies per facilitar els enllaços.
                          </strong>{" "}
                          Revisarem si es correcte i si hi ha qualsevol
                          incidencia ens posarem en contacte amb tu.
                        </Alert>
                      )}
                      <Card bg="light" className='p-3 pb-2 mb-4'>
                      <Form.Group
                        controlId="filmLinkDownload"
                        className="mb-3"
                      >
                        <Form.Label>
                          <strong>Enllaç de descàrrega</strong> de l'arxiu
                          de projecció
                          <br />
                          <small className="text-muted">
                            MP4 o MOV (en HD i bitrate elevat) o Format DCP
                            digital{" "}
                          </small>
                        </Form.Label>
                        <Form.Control
                          required
                          type="url"
                          defaultValue={
                            film.filmLinkDownload
                              ? film.filmLinkDownload
                              : ""
                          }
                          placeholder="Ex: http://drive.google.com/1234"
                        />
                        <Form.Control.Feedback type="invalid">
                          {MESSAGES.FIELD_REQUIRED}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        controlId="filmLinkTrailer"
                        className="mb-3"
                      >
                        <Form.Label>
                          <strong>
                            {(filmType === "videoclips" || filmType === "jove")
                              ? "Enllaç"
                              : "Enllaç al trailer"}{" "}
                          </strong>
                          en streaming{" "}
                          {(filmType !== "videoclips" && filmType !== "jove") ? (
                            <span className="text-info">(Opcional)</span>
                          ) : (
                            ""
                          )}
                          <br />
                          <small className="text-muted">
                            Només s'accepten enllaços de Youtube o Vimeo.
                          </small>
                        </Form.Label>
                        <Form.Control
                          type="url"
                          required={
                            (filmType === "videoclips" || filmType === "jove") ? true : false
                          }
                          defaultValue={
                            film.filmLinkTrailer ? film.filmLinkTrailer : ""
                          }
                          placeholder="Ex: https://www.youtube.com/watch?v=7uZ6vDQM34c"
                        />
                        <Form.Control.Feedback type="invalid">
                          {MESSAGES.FIELD_REQUIRED}
                        </Form.Control.Feedback>
                      </Form.Group>
                      </Card>
                      <Button
                        type="submit"
                        className="btn btn-lg btn-success mb-4"
                      >
                        <strong>Guardar canvis</strong>
                      </Button>
                    </Col>
                  </Row>
                </Form>
                    ) : (
                      <>
                        {CONFIG.MATERIAL_MOVIE_DATE - Date.now() < 0 ? (
                          <Alert variant="info">
                            <FaInfoCircle /> Ja hem tancat la recepció d'arxius
                            de projeccions. En cas de que hi hagi qualsevol
                            problema contacta amb:{" "}
                            <a href="mailto:programacio@fic-cat.cat">
                              programacio@fic-cat.cat
                            </a>
                          </Alert>
                        ) : (
                          <Alert variant="success">
                            {" "}
                            <PiCheckCircleFill />{" "}
                            <strong>
                              Ja hem descarregat l'arxiu de projecció.
                            </strong>{" "}
                            Per qualsevol problema pots posar-te en contacte amb
                            nosaltres a{" "}
                            <a href="mailto:programacio@fic-cat.cat">
                              programacio@fic-cat.cat
                            </a>
                          </Alert>
                        )}
                      </>
                    )}

               
              </Container>
            ) : (
              <Container>
                <Alert variant="warning" >
                  <PiWarningDiamondFill />
                  <div style={{ display: 'none'}}>
                    {window.location = ( window.location.origin + ROUTES['SIGN_UP_FILMAKERS'] + '/' + filmType.toLowerCase() + '/' + year  + '/' + filmUid) }
                  </div>
                  <strong>No tens permisos</strong> per administrar aquesta producció. Per qualsevol incidència si us plau contacta amb nosaltres a <a href="mailto:programacio@fic-cat.cat" target="_blank">programacio@fic-cat.cat</a>
                </Alert>
              </Container>
            )}
            </>
        ) : (
          <Alert variant="warning" className="my-4">
            <PiWarningDiamondFill /> <strong>Aquest formulari esta tancat.</strong> Per a qualsevol incidencia contacta amb nosaltres a <a href="mailto:programacio@fic-cat.cat">programacio@fic-cat.cat.</a>
          </Alert>
        )}
      </Container>
      ) : (
        <Loading />
      )}
      </>
    );
  }
}

const condition = () => { if (U.isUserFilmaker() || U.isUserContentManager() ) { return true } else { return false} }

export default compose(
  withAuthorization(condition, true),
)(FilmsSelectedStuff);
