import React, { Component } from 'react';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import Form from 'react-bootstrap/Form';
import ModalImage from "react-modal-image";
import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';

import { Rating } from 'react-simple-star-rating'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loading from '../Loading';
import FilmsBadges from '../FilmsBadges';
import { Alert, Container } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { PiWarningDiamondFill } from 'react-icons/pi';
//import FilmList from '../Films';

let filmType = '';
let filmUid = '';
let edition = '';

if (localStorage.getItem('currentEdition') === null ) {
  edition = CONFIG.CURRENT_EDITION;
} else {
  edition = localStorage.getItem('currentEdition');
} 

class HomePage extends Component {
  constructor(props) {
    super(props);
    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    filmUid = props.match.params.uid ? props.match.params.uid : null;

    this.state = {
      type: null,
      validated: null,
      loading: true
    }

    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.type = 'JOVES';
      break;
      case 'llargs':
      default:
        this.state.type = 'LLARGS';
      break;
    }
  }

  componentDidMount () {
    this.props.firebase.database().ref( CONFIG.USER_NOTES + U.authUser().uid + '/' + filmUid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.setState({
          userFilm: snapshotUserFilm.val()
        });


      } else {
        this.setState({
          userFilm: {
            userFilmNote: '',
            userFilmRank: ''
          }
        });
      }
    })
  }
  render () {
    return (
      <div>
        {this.state.userFilm && 
          <Films userFilm={this.state.userFilm} />
        }
        {!this.state.userFilm &&
            <Loading />
        }
      </div>
    )
  }
}

class FilmsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userFilm: props.userFilm,
      loading: true,
      film: null,
      API: null,
    };

    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.short(filmUid);
        this.filmTypeName = 'CURTS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.doc(filmUid);
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.school(filmUid);
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.music(filmUid);
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.API = this.props.firebase.young(filmUid);
        this.filmTypeName = 'JOVES';
      break;
      case 'llargs':
        default:
          this.state.API = this.props.firebase.film(filmUid);
          this.filmTypeName = 'LLARGS';
        break;
    }

  }
  componentDidMount() {

    this.state.API.once('value', snapshot => {
      
      if (snapshot) {
        // convert messages list from snapshot
        this.setState({
          film: snapshot.val(),
        });

        if (this.state.film && (this.state.film.filmLink || this.state.film.eventLinkYouTube)) {
          // - Supported YouTube URL formats:
          //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
          //   - http://youtu.be/My2FRPA3Gf8
          //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
          // - Supported Vimeo URL formats:
          //   - http://vimeo.com/25451551
          //   - http://player.vimeo.com/video/25451551
          // - Also supports relative URLs:
          //   - //player.vimeo.com/video/25451551
    
          let filmEmbed = this.state.film.eventLinkYouTube != undefined ? this.state.film.eventLinkYouTube : this.state.film.filmLink; 
      

          if ( filmEmbed.indexOf('drive.google.com') > -1) {
            filmEmbed.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|drive.google\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|file\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?\/([A-Za-z0-9._%-]*)(\S+)?/);
            this.setState( { film: {...this.state.film, filmLinkType:'drive' ,filmLinkId: RegExp.$8 } })
          } else if (filmEmbed.indexOf('youtu.be') > -1) {
            this.setState( { film: {...this.state.film, filmLinkType:'youtube' ,filmLinkId: filmEmbed.split('/')[3] } })  
          } else {
            filmEmbed.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/);
            if (RegExp.$3.indexOf('vimeo') > -1) {
              this.setState( { film: {...this.state.film, filmLinkType:'vimeo' ,filmLinkId: RegExp.$6 } })
            } else if (RegExp.$3.indexOf('drive.google.com') > -1) {
              this.setState( { film: {...this.state.film, filmLinkType:'drive' ,filmLinkId: RegExp.$6 } })
            } else if (RegExp.$3.indexOf('youtube') > -1) {
              this.setState( { film: {...this.state.film, filmLinkType:'youtube' ,filmLinkId: RegExp.$6 } })
            }
          }
        }
        function resizedImage( imageUrl ) {
          if (imageUrl) {
            let imageURlResponse = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[1];


            if (imageURlResponse !== undefined) {
              return imageURlResponse.split('.')[0] + '_800x800.jpeg';
            }  else {
            return false
          }

          } else {
            return false
          }
        }

        const images = this.props.firebase.storage().ref().child( CONFIG.ACTIVE_EDITION + '_images/thumbs/');
        
        if (this.state.film) {
          
          if (this.state.film.imageCover  && resizedImage(this.state.film.imageCover) !== false) {
            const imageMain = images.child(resizedImage(this.state.film.imageCover));
            imageMain.getDownloadURL().then(
              (url) => { this.setState({ filmImageMain: url });
            });
          }

          if (this.state.film.imageFrame1 && resizedImage(this.state.film.imageFrame1) !== false) {
            const imageFrame1 = images.child(resizedImage(this.state.film.imageFrame1));
            imageFrame1.getDownloadURL().then(
              (url) => { this.setState({ filmImageFrame1: url });
            });
          }
          if (this.state.film.imageFrame2  && resizedImage(this.state.film.imageFrame2) !== false) {
            const imageFrame1 = images.child(resizedImage(this.state.film.imageFrame2));
            imageFrame1.getDownloadURL().then(
              (url) => { this.setState({ filmImageFrame2: url });
            });
          }
          if (this.state.film.imageFrame3  && resizedImage(this.state.film.imageFrame3) !== false) {
            const imageFrame1 = images.child(resizedImage(this.state.film.imageFrame3));
            imageFrame1.getDownloadURL().then(
              (url) => { this.setState({ filmImageFrame3: url });
            });
          }

          
          this.setState({ 
            isSelectable: this.state.film.isSelectable,
            isSelected: this.state.film.isSelected,
            loading: false,
          });
          }  else {
            this.setState({ 
              film: false,
          });
        }

      }
        

    });
  }


  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }

  updateIsSelectable(e) {

    let data = this.state.film;
    
    if ( e.target ) {
      data['isSelectable'] = JSON.parse(e.target.checked);
      this.setState({ isSelectable: JSON.parse(e.target.checked)});

    }
    this.state.API.set(data);    
  }


  sendUserFilm(e) {
   
    e.preventDefault();

    const form = e.currentTarget;
    this.setState({ validated: 'validated'});

    if (form.checkValidity() === false) {
      // document.documentElement.scrollTop = 0;
      e.stopPropagation();
    } else {
      const params = {
        userFilmRank: this.inputUserFilmRank ? Math.round(this.inputUserFilmRank.value) : null,
        userFilmNote: this.inputUserFilmNote ? this.inputUserFilmNote.value : null
      };
      
      this.props.firebase.database().ref( CONFIG.USER_NOTES + U.authUser().uid + '/' + filmUid).set(params).then(() => {
        // this.showAlert('success', 'Moltes gràcies per inscriure la teva producció al FIC-CAT. Molta sort!');
      }).catch(() => {
        // this.showAlert('danger', 'Hi ha algun error al formulario, si us plau repasa-ho.');
      });
      // this.resetForm();
    }
  }

  render() {
    const { film, userFilm, loading } = this.state;
    const filmTypeName = this.filmTypeName;
    const showRate =  ((CONFIG.RATE_FILMS && (CONFIG['COMITE_' +  this.filmTypeName ].indexOf(U.authUser().email) !== - 1 ? true : false) && (CONFIG.CURRENT_EDITION === edition || U.isUserAdmin())  ) ||  U.isUserJury() );
    return (
      <Container>
        {!loading ? (
          <div className="row">
              <div className='col-sm-8'>
              
                    <div className='row'>
                      <Container>
                      <FilmsBadges key={film.uid} film={film} filmType={filmType}/>
                       <h1 style={{ lineHeight: '.95em', color: '#dc3545', display: 'block', paddingTop:'10px', borderBottom: '2px solid #dc3545' }}>{film.filmName}&nbsp;
                          <small style={{ color: '#000', fontSize: '1rem', display: 'inline-block' }}> de&nbsp;
                                <strong>{film.filmDirector ? film.filmDirector : film.contactName }</strong>
                          </small>

                        </h1>  
                        </Container>      
                        <div className="col-sm-8">              
                        <p>

                        { film.filmSchool && (
                          <>
                            de <strong>{film.filmSchool}</strong> <small className='text-muted'>({film.filmSchoolLevel})</small><br/> per {film.filmDirector}<br />
                          </>
                        ) || (
                          <>
                              { film.filmBand && (
                                <>
                                  Videoclip de <strong>{film.filmBand}</strong> per&nbsp;
                                </>
                              )}
                              { film.producerCompany && (
                                <>
                                  <span className='text-muted'>produit per {film.producerCompany}</span><br />
                                </>
                              )}
                            
                          </>
                        )}
                        <small className='text-muted'>
                          <strong>{film.filmYear} · {film.filmLengthFixed ? film.filmLengthFixed : film.filmLength} min.· {film.filmGenre ? film.filmGenre : ''}· {film.filmCity}</strong>, {film.filmState} ({film.filmCountry.substring(0,3).toUpperCase()})
                          
                          {film.filmSignDate && (
                            <i>
                              <br />
                              Data d'inscripció: {film.filmSignDate}
                            </i>
                          )}

                        </small><br /></p>
                          {film.filmActoring && (
                          <div style={{ padding: '0 0 10px', display: 'block' }}>
                            <strong>Intèrprets principals:</strong> {film.filmActoring}
                            </div>
                            )}
                            {film.filmSynopsis && (
                          <div style={{ padding: '0 0 10px', display: 'block' }}>
                            <strong>Sinopsi:</strong> {film.filmSynopsis}
                          </div>
                          )}
                            {film.filmLanguage && (
                          <div style={{ padding: '0 0 10px', display: 'block' }}>
                            <strong>Idiomes:</strong> {film.filmLanguage}
                          </div>
                           )}
                        </div>
                        <div className="col-sm-4 pt-2">
                              {film.imageCover && (
                                <>
                                  <ModalImage
                                    small={this.state.filmImageMain}
                                    large={film.imageCover}
                                    alt={film.filmName}
                                  />
                                </>
                              )}
                        </div>
                    </div>
                    {U.isUserCommittee() && (
                    <>
                    { film.filmLinkType === 'vimeo' &&
                      <div style={{ margin: '20px 0 10px', width: '100%', border:'1px solid #CCC', padding: '0 0 50% 0', background: '#000', display: 'block', position: 'relative'}}>
                        <iframe title="vimeo"  src={"https://player.vimeo.com/video/" + film.filmLinkId } style={{  width: '100%', height: '100%', position: 'absolute'}} frameBorder="0" allow="autoplay; fullscreen;" ></iframe>
                      </div>
                    }
                    { film.filmLinkType === 'youtube' &&
                      <div style={{ margin: '20px 0 10px', width: '100%',border:'1px solid #CCC', padding: '0 0 50% 0', background: '#000', display: 'block', position: 'relative'}}>
                        <iframe title="youtube" style={{  width: '100%', height: '100%', position: 'absolute'}}  src={"https://www.youtube.com/embed/" + film.filmLinkId } frameBorder="0" allow="fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                       { film.eventLinkYouTube && !film.imageCover && ( 
                         <div>
                          <img src={"http://i3.ytimg.com/vi/" + film.filmLinkId + "/maxresdefault.jpg"} style={{ maxWidth: '100%' }} />
                          <img src={"http://i3.ytimg.com/vi/" + film.filmLinkId + "/hqdefault.jpg"} style={{ maxWidth: '100%' }} />
                          </div>
                        )}
                      </div>
                    }
                    { film.filmLinkType === 'drive' &&
                    <div style={{ margin: '20px 0 10px', width: '100%', border:'1px solid #CCC', padding: '0 0 50% 0', background: '#000', display: 'block', position: 'relative'}}>
                      <iframe title="drive" src={film.filmLink.replace('view?','preview?')} frameBorder="0" style={{  width: '100%', height: '100%', position: 'absolute'}} allow="autoplay; fullscreen;" ></iframe>
                    </div>
                    }
                    <Row className='pb-4'>
                      { film.filmPass && 
                      <Col sm={4}>
                        <dl>
                          <dt>Password:</dt>
                          <dd>{film.filmPass}</dd>
                        </dl>
                      </Col>
                      }
                     { film.filmLink && (
                       <Col sm={8}>
                        <dl>
                          <dt>Enllaç de visionat:</dt>
                          <dd className='text-truncate'>
                            <a href={film.filmLink} target="_blank" rel="noopener noreferrer">{film.filmLink}</a>
                          </dd>
                        </dl>
                      </Col> 
                      )}
                      <div className='col-sm-12'>
                        </div>
                    </Row>
                    </>
                    )}
                    { (this.state.filmImageFrame1 || this.state.filmImageFrame2 || this.state.filmImageFrame3 ) &&
                      <Row className='py-4'>
                        <Col sm className='pb-2'>
                          {this.state.filmImageFrame1  && ( 
                            <>
                              <ModalImage
                                small={this.state.filmImageFrame1}
                                large={film.imageFrame1}
                                alt={film.filmName}
                              />
                            </>
                          )}
                        </Col>
                        <Col sm className='pb-2'>
                          {this.state.filmImageFrame2 && ( 
                            <>
                              <ModalImage
                                small={this.state.filmImageFrame2}
                                large={film.imageFrame2}
                                alt={film.filmName}
                              />
                            </>
                          )}
                        </Col>
                        <Col sm className='pb-2'>
                          { this.state.filmImageFrame3 && ( 
                            <>
                              <ModalImage
                                small={this.state.filmImageFrame3}
                                large={film.imageFrame3}
                                alt={film.filmName}
                              />
                            </>
                          )}
                        </Col>
                      </Row>
                    }
                    {U.isUserCommittee() && (
                      <>
                    { !U.isUserJury() && (
                    <Row className='mt-2'>
                      <Col xs={8}>
                        <dl>
                        { film.filmSpecialEdition && (
                          <>

                          <dt>E-mail director/a:</dt>
                          <dd>{film.filmDirectorEmail}</dd>
                            <dt>Muntador/a:</dt>
                            <dd>{film.filmSpecialEdition}</dd>
                          </>

                        )}
                          <dt>Persona de contacte:</dt>
                          <dd>{film.contactName}</dd>
                          {film.contactRole && (
                          <>
                          <dt>Rol:</dt>
                          <dd>{film.contactRole}</dd>
                          </>
                          )}
                          <dt>E-mail:</dt>
                          <dd>{film.contactEmail}</dd>
                          <dt>Telefon:</dt>
                          <dd>{film.contactPhone}</dd>
                      </dl>
                        { film.producerCompany &&
                          <dl>
                            <dt>Web de la productora:</dt>
                            <dd className='text-truncate'>{film.producerSite}</dd>
                            { film.producerName && (
                              <>
                                <dt>Nom del productor/a:</dt>
                                <dd>{film.producerName}</dd>
                              </>
                            )}
                          </dl>
                          }
                            {film.filmFestivals && 
                          <dl>
                            <dt>Premis i Festivals:</dt>
                            <dd>{film.filmFestivals}</dd>
                          </dl>
                          }
                          { film.filmSchoolTutor &&
                          <dl>
                            <dt>Nom del tutor/a responsable de l'obra:</dt>
                            <dd>{film.filmSchoolTutor}</dd>
                          </dl>
                          }
                           { film.message &&
                            <dl>
                              <dt>Info adicional:</dt>
                              <dd>{film.message}</dd>
                          </dl>
                          }
                      </Col>
                        <Col xs={4}>
                          <dl>
                              <dt>Estrena?</dt>
                              <dd>{film.filmPremiere ? film.filmPremiere : '?'}</dd>
                              <dt>Premi Online?</dt>
                              <dd>{film.acceptOnline ? film.acceptOnline.replace('Si','Sí') : '-'}</dd>
                              <dt>Vol rebre informació?</dt>
                              <dd>{ film.acceptInfo ? film.acceptInfo.replace('Si','Sí') : '-'}</dd>
                              <dt>Accepta les Bases?</dt>
                              <dd>{film.acceptLegal ? film.acceptLegal.replace('Si','Sí') : '-'}</dd>
                              <dt>Propietari dels drets?</dt>
                              <dd>{film.acceptOwn ? film.acceptOwn.replace('Si','Sí') : '-'}</dd>
                            </dl>
                        </Col>
                      </Row>
                      
                      )}
                      </>
                    )}
              </div>
              <div className='col-sm-4'>
              {( U.isUserAdmin() ) && (
                <Alert variant="info text-center">
                  <FaInfoCircle /> 
                  <Form  id="isSelectableForm" ref='isSelectableForm' >  
                  <Form.Check
                    inline
                    label='Compleix les bases'
                    className={film.isSelectable === true ? 'font-weight-bold' : 'text-muted'} 
                    name="isSelectable"
                    onChange={this.updateIsSelectable.bind(this)}
                    type='switch'
                    defaultChecked={film.isSelectable === true}
                    id='isSelectableTrue'
                  />
                </Form>
                </Alert>
                )}
              { showRate && (
                <div className='bg-light' style={{ border: '1px solid #CCC', padding: '0 20px' }}>
   
                  <Form noValidate validated={ this.state.validated } id="userFilm" onSubmit={this.sendUserFilm.bind(this)} style={{ padding: '20px 0'}} >
                   
                    <Form.Group controlId="userFilmRank">
                      <Form.Label><strong>Valoració</strong></Form.Label>
                      <div>
                        <Rating initialValue={ userFilm ? userFilm.userFilmRank : 0} onClick={(value) => { document.getElementById('userFilmRank').value = Math.round(value); document.getElementById('submitRank').click( )}}/>
                      </div>
                      <Form.Control as="select"
                        required
                        style={{ display: 'none'}}
                        defaultValue={userFilm ? userFilm.userFilmRank : ''}
                        ref={userFilmRank => this.inputUserFilmRank = userFilmRank}
                      >
                        <option value=''>Selecciona una opció...</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        </Form.Control>
                      <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="userFilmNote">
                      <Form.Label><strong>Notes sobre la valoració</strong><br/><small>(Camp només visible per a tu)</small></Form.Label>
                      <Form.Control 
                        defaultValue={userFilm.userFilmNote}
                        as="textarea" rows="8" ref={userFilmNote => this.inputUserFilmNote = userFilmNote} />
                    </Form.Group>
                    <Form.Group className='mt-3'>
                      <button id="submitRank" type='submit' className='btn btn-lg btn-success'><strong>Guardar valoració</strong></button>                      
                    </Form.Group>
                  </Form>
                  
              </div>
              )}
                  { CONFIG.RATE_FILMS !== true && ( 
                    <div className='bg-light' style={{ padding: '10px 0 0 10px', display: 'block', overflow: 'hidden' }}>
                        <p><b>La teva valoració:</b></p>
                        <h2>{userFilm.userFilmRank ? userFilm.userFilmRank : ''}</h2>
                        {userFilm.userFilmNote ? userFilm.userFilmNote : ''}
                        <br/><br/>
                      </div>
                   )}
            </div>
          </div>
        ) : (
          <>
          { film !== false ? (
            <Loading />
          ) : (
              <Row className='mt-4 justify-content-md-center'>
                <Col lg={6}>

            <Alert variant='warning'>
              <PiWarningDiamondFill />
              <p><strong>No existeix cap curt amb l'id:</strong><br/> {filmUid} </p>
              {!U.isUserJury() && (
                <>
              <p>Probablement has canviat el selector d'any en la pàgina d'una producció. Per trobar la producció canviar l'any o torna al llistat de produccions.</p>
              <a href={ROUTES['LIST_' + filmTypeName]} className='btn btn-primary'>Tornar al llistat de {filmType}</a>
              </>
            )}
            </Alert>
            </Col>
            </Row>
          )}
          </>
        )}
      </Container>
    );
  }
}
const Films = withFirebase(FilmsBase);

const condition = () => { if (U.isUserCommittee()|| U.isUserProvider() || U.isUserJury()) { return true } else { return false}};

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(HomePage);