import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import ListGroup from 'react-bootstrap/ListGroup'


import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';
import { Container } from 'react-bootstrap';

let filmType = '';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    this.state = {
      type: null,
      API: null,
      filmList: undefined,
      filmListTotal: 0,
      filmStates: {},
    }

    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.shorts();
        this.state.type = 'CURTS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.docs();
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.schools();
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.musics();
        this.state.type = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.API = this.props.firebase.youngs();
        this.state.type = 'JOVES';
      break;
      case 'llargs':
        default:
          this.state.API = this.props.firebase.films();
          this.state.type = 'LLARGS';
        break;
    }

    // GET FILM LIST
    this.state.API.on('value', snapshot => {
      const filmObject = snapshot.val();
      if (filmObject) {
        const filmList = Object.keys(filmObject).map(key => ({
          ...filmObject[key],
          uid: key,
        }));
        this.setState({ filmList: filmList})
        this.countFilmList();
        this.filterFilmsByCategory();
      }
    })
  }
  componentDidUpdate() {
    
  }
  countFilmList() {
    let size = 0,
        key;
    for (key in this.state.filmList) {
      if (this.state.filmList.hasOwnProperty(key)) size++;
    }
    this.setState({filmListTotal: size})
  } 
  filterFilmsByCategory() {
    const filmList = this.state.filmList;
    if (filmList) {
      let tempStates = []
      let tempStatesObject = {}

      if (filmList[1] !== undefined) {
        tempStates = filmList.reduce((accumulator, current) => {
          tempStatesObject[current.filmState] = ( tempStatesObject[current.filmState] || 0) + 1;
          return tempStatesObject;
          })
      } else {
        tempStates[filmList[0].filmState] = 1;
      } 



      //tempStates = Object.entries(tempStates).sort((a,b) => b[1]-a[1]); // .map(el=>el[0])      
      this.setState({ filmStates: tempStates});
    }
  }
  showFilmsByCategory() {
    const filmStates = this.state.filmStates ? this.state.filmStates : {};
    const itemRows = [];
    let rowNum = 0;
    Object.keys(filmStates).forEach(function(key) {
        const row = (
          <ListGroup.Item key={key} variant={ rowNum % 2  == 0 ? '' : 'secondary' }>{key} : {filmStates[key]}</ListGroup.Item>
        );
        itemRows.push(row);
        rowNum++
    })
    return (
      <ListGroup>
        {itemRows} 
      </ListGroup>
    )
  }
  totalFilms() {
    const data = this.state.filmStates;
    const filmStates = this.state.filmStates
    return (
      <>
        <Container>
            <h1>Benvingut/da</h1>
            <p>Si us plau, selecciona una opció del menú superior per veure les produccions per categoria.</p>
            <img src='/img/crispeta-bg.jpg' style={{ maxWidth: '100%', border: '4px solid #000'}} />
        </Container>
      </>
    )
  }
  render () {


    return (
     <>
        {this.totalFilms()}
     </>
    )
  }
}

const condition = () => { return  !U.isUserAnonymous()}

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(Dashboard);