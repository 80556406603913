import Axios from 'axios';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';


import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { Card, Col, Container, Row, DatePicker } from 'react-bootstrap';
import SessionForm from '../SessionForm';

class FilmsForm extends Component {

  constructor(props) {
    super(props);
    this.setRef = ref => {
      this.file = ref;
    }
    this.formData = {};

    // Get User role and identify if it's admin or editor
    this.admin = U.isUserAdmin() ? true : false
    this.editor = U.isUserEditor() ? true : false

    // Set default state
    this.state = {
      form: [],
      testSubmit: false, // TRUE or FALSE to make form simple to test
      success: false,
      premiere: true,
      country: 'Espanya',
      state: undefined,
      category: undefined,
      validated: null,
      formStructure: [],
      data: {},
    };

    const code = this.props.location.search.split('?code=')[1];



    if (code !== undefined && CONFIG.SIGNIN_FILMS_CODE.indexOf(code) !== -1) {
      this.overRideAccess = true;
    } else {
      this.overRideAccess = false;
    } 

    // Identify category based on URL match
    const handle = this.props.match.path
    switch(handle) {
      case '/curts':
          this.state.type = 'CURTS';
      break;
      case '/documentals':
          this.state.type = 'DOCS';
      break;
      case '/centres-educatius':
          this.state.type = 'CENTRES';
      break;
      case '/videoclips':
          this.state.type = 'VIDEOCLIPS';
      break;
      case '/jove':
        this.state.type = 'JOVES';
      break;
      case '/llargs':
      default:
          this.state.type = 'LLARGS';
      break;
    }

    // Get form structure & current edition from CONFIG
    this.state.formStructure = CONFIG[this.state.type];
    this.Edition = 'films/' + CONFIG.CURRENT_EDITION + '/' + this.state.type;

  }

  sendForm(e) {
   
    e.preventDefault();

    const form = e.currentTarget;
    this.setState({ validated: 'validated'});

    let params = {};

    if (form.checkValidity() === false) {
      document.documentElement.scrollTop = 0;
      e.stopPropagation();
    } else {
      params = {
        name: form.elements['name'].value,
        date: form.elements['date'].value,
        time: form.elements['time'].value,
        location: form.elements['location'].value,
        type: form.elements['type'].value,
        logistic: form.elements['logistic'].value,
        session: {
          date: form.elements['sessionDate'].value,
          uid: form.elements['sessionTime'].value
        },


      };

      // WRITE ON DATABASE AND SEND EMAIL IF SUCCESS
      this.props.firebase.sessions().push(params).then((params) => {
        
        this.setState({ success: true });
        
      }).catch(() => {
        this.setState({ success: false })
      });
    }
  }

  successMessage() {

    return (
      <div className="container">
        <div className='col-sm-6' style={{ margin: '0 auto'}}>
          <div className='card'>
            <div className="card-body">
              <img alt="FIC-CAT" style={{ width: '100px', margin: '0 20px 10px 0' }} src="/img/logo.png" />
              <h5 className="card-title">Gràcies per la teva inscripció!</h5>
              <p className="card-text">Hem rebut correctament la teva producció. Ens posarem en contacte amb tu per correu electrònic per comunicar el resultat de la selecció.</p>
              <Button href={ROUTES.LANDING} variant="success">Inscriure una altre producció</Button>
            </div>
            <img className='card-img-bottom' src={"/img/" + this.state.type.toLowerCase()  + ".jpg"} alt="FIC-CAT" />
          </div>
        </div>
      </div>
    );
  }


  contactInfoForm(){
    return (
      <div className='pb-4'>
          <Card bg="light" className='p-3 pb-2 mb-4'>
            <SessionForm variant="add" />
          </Card>
        </div>
    )
  }

  


  render () {
    return (
      <div>
        { (CONFIG.SIGNIN_FILMS || ( CONFIG.SIGNIN_YOUNG && this.state.type === 'JOVES' ) || ( U.isUserContentManager()) || (this.overRideAccess === true) ) ? (
        <>
        { this.state.success === true && ( 
          this.successMessage()
        )}
        { (this.state.success !== true && U.isUserAdmin() ) && (
          <Container>
            <div className='row'>
              <div className='col-sm-12'>
                <h1 className='mt-4 mb-0'> Crear sessió <strong className='text-primary'>{CONFIG.CURRENT_EDITION}</strong></h1>
                <p className="text-muted pb-4">{ MESSAGES[this.state.type + '_FORM_DESC'] }</p>
                <Form noValidate validated={ this.state.validated } id="formFilm" onSubmit={this.sendForm.bind(this)} ref='contactForm' >
                  {/* Render CONTACT info form if is defined in form structure */}
                  { this.contactInfoForm() }
                  <button type='submit' className='btn btn-lg btn-success'><strong>Crear</strong></button>
                </Form>
              </div>
            </div>
          </Container>
        )}
        </>
        ) : ( 
          <Container>
            <img alt="FIC-CAT" style={{ width: '100px', margin: '0 20px 10px 0' }} src="/img/logo.png" />
            <h2 className='mt-4 pb-2'>{MESSAGES.SIGIN_FALSE_TITLE}</h2>
            <Alert variant='warning' >{MESSAGES.SIGIN_FALSE_DESC}</Alert>
          </Container>
        )}
      </div>
    )
  }
}



export default compose(
  withFirebase
)(FilmsForm);
