import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import Card from 'react-bootstrap/Card';

import { getImageSize } from 'react-image-size';
import ModalImage from 'react-modal-image';
import FileUploader from '../FileUploader';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';

import { Form } from 'react-bootstrap';
import CardBody from 'react-bootstrap/esm/CardBody';
import { PiCheckCircleFill, PiCrossFill, PiWarningDiamondFill } from 'react-icons/pi';
import { MdCancel } from 'react-icons/md';


class FilmsEditImages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      API: null,
      filmType: props.filmType,
      firebase: props.firebase,
      film: props.film,
      imageName: props.imageName,
      mode: props.mode ? props.mode : false,
      imageDimensions: {
        width: 0,
        height: 0
      },
      imageDimensionsCompliant: true,
      maxSize: props.maxSize ? props.maxSize : 20000000,
      minSize: props.minSize ? props.minSize : 1000000,
      maxWidth: props.maxWidth ? props.maxWidth : 1200,
      maxHeight: props.maxHeight ? props.maxHeight : 1200
    }

    switch(props.filmType) {
      case 'curts':
        this.state.API = this.props.firebase.short(props.filmUid);
      break;
      case 'documentals':
        this.state.API = this.props.firebase.doc(props.filmUid);
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.school(props.filmUid);
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.music(props.filmUid);
      break;
      case 'jove':
        this.state.API = this.props.firebase.young(props.filmUid);
      break;
      case 'llargs':
        default:
          this.state.API = this.props.firebase.film(props.filmUid);
        break;
    }

  }

  componentDidMount () {
    this.fetchImageSize(this.state.film[this.state.imageName], this.state.imageName + 'Dimensions');
  }


  async fetchImageSize(imageUrl, newField) {
    try {
        const a1 = new Image;
        a1.src=imageUrl;
        await getImageSize(imageUrl).then(
          result => ( this.setState({ imageDimensions : result } )
        ));
    } catch (error) {
    }
}


updateIs(e) {

  let data = this.state.film;
  let modified = false;

  if (data.filmName) {
    switch(e.target.type) {
      case 'checkbox':
        data[e.target.name] = JSON.parse(e.target.checked);
        modified = true;
      break;
      case 'select-one':

        // data['isPremiereType'] = e.target.value;
        // modified = true;

      break;
    }
    if (modified) {
      this.setState({ film: data });
      //this.fetchImageSize(this.state.film[this.state.imageName], this.state.imageName + 'Dimensions');
      this.state.API.set(data);    
    }
  }

}
  
  resizedImage( imageUrl ) {
    return imageUrl;
    
    if (imageUrl) {
      let path = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[0] + CONFIG.ACTIVE_EDITION + '_images%2Fthumbs%2F';
      let imageURlResponse = imageUrl.split( CONFIG.ACTIVE_EDITION + '_images%2F')[1];
      let token = imageUrl.split( 'token')[1];

      if (imageURlResponse !== undefined) {
       return path + imageURlResponse.split('.')[0] + '_800x800.jpeg' + '?alt=media&token' + token;
      }  else {
      return false
    }

    } else {
      return false
    }
  }

  handleUploadSuccess = (url, imageField) => {

    if (url !== undefined) {

      let data = this.state.film;

      data[imageField] = url;

      this.setState({ film: data });

      if (data.filmName && data[imageField]) {
        this.state.API.set(data);    
      }
    }
    
  }


  render () {
    const { film, imageName, maxSize, minSize, imageDimensions, maxWidth, maxHeight, mode} = this.state;
    return (
      <Card className='mb-4'>
        <CardBody className='p-4 pb-2'>
        <h5>{MESSAGES[imageName.toUpperCase()]}</h5>
        <dl className='mb-0'>
          <dt>
            Format
          </dt>
          <dd>
          { film[imageName] ? <PiCheckCircleFill className='icon text-success' /> : <MdCancel className='icon text-danger' /> } Format JPG o similar.
          </dd>
          <dt>
            Tamany:
          </dt>
          <dd>
          { film[imageName] ? <PiCheckCircleFill className='icon text-success' /> : <MdCancel className='icon text-danger' />}  Mínim {minSize/1000000 > 0 ? minSize/1000000 + 'MB' : minSize/100000 +'KB' }, máxim {maxSize/1000000 > 0 ? maxSize/1000000 + 'MB' : maxSize/100000 +'KB' }.
          </dd>
          <dt>Dimensions en píxels:</dt>
          <dd>
            <span>{ (imageDimensions.width > 1200 && imageDimensions.height > 1200) ? <PiCheckCircleFill className='icon text-success' /> : <PiWarningDiamondFill className='icon text-warning' /> }&nbsp;</span>
            {(imageDimensions.width > 1200 && imageDimensions.height > 1200) ? (
            <span>
              {imageDimensions.width} x {imageDimensions.height}       
            </span>
            ) : (
              <span>Recomanat: {maxWidth}x{maxHeight}</span>
            )}
          </dd>
          { mode === 'admin' && (
          <>
          <dt>
            Permisos
          </dt>
          <dd>
            <Form id={ imageName + 'InvalidForm'} ref={ imageName + 'InvalidForm'} >  
                <Form.Check
                  inline
                  label="Sobrescriure"
                  className={ film[imageName + 'Invalid'] === true ? 'text-danger font-weight-bold' : 'text-muted'} 
                  id={ imageName + 'Invalid'}
                  name={ imageName + 'Invalid'}
                  onChange={this.updateIs.bind(this)}
                  type='switch'
                  defaultChecked={film[imageName + 'Invalid'] === true }
                />
              </Form>
          </dd>
          </>
          )}
        </dl>
        </CardBody>
            { (film[imageName + 'Invalid'] !== true && film[imageName] &&  imageDimensions.width > maxWidth && imageDimensions.height > maxHeight ) ? (
              <ModalImage
                  small={this.resizedImage(film[imageName])}
                  large={film[imageName]}
                  alt={film.filmName}
                >
              </ModalImage>
            ) : (
              <div className='px-4 pb-3'>
              <FileUploader
                hidden
                accept="image/*"
                imageField={imageName}
                imageUrl={film[imageName]}
                maxSize={maxSize}
                minSize={minSize}
                onUploadSuccess={this.handleUploadSuccess}
              >

              </FileUploader>
              </div>
            )}
      </Card>
    )
  }
}

export default compose(
  withFirebase
)(FilmsEditImages);