import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash'

import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'

import { Link } from 'react-router-dom';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';


import FilmsTopItem from '../FilmsTopItem';
import FilmsListFilters from '../FilmsListFilters';

import { Container, Row } from 'react-bootstrap';
import Loading from '../Loading';
import { PiWarningDiamondFill } from 'react-icons/pi';

let filmType = '';
let filter = false;
let userRanking = 'comite';


class HomePage extends Component {
  constructor(props) {
    super(props);
    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    filter = props.match.params.role ? props.match.params.role : 'comite';
    this.state = {
      type: null
    }
    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'llargs':
        this.state.type = 'LLARGS';
      default:
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
    }
  }
  UNSAFE_componentWillReceiveProps() {
    switch(filmType) {
      case 'curts':
      case 'llargs':
      case 'documentals':
      case 'videoclips':
      default:
        userRanking = 'oficial';
      break;
      case 'centres-educatius':
        userRanking = 'centres';
      break;
    }
  }
  render () {
    const { type, filmTypeName } = this.state;
    return (
      <Container>
        { U.showTopFilms() ? (
            <>
              <Films />
            </>
          ) :(
            <>
            <h1>Ooops, encara no</h1>
            <p>Fins que no hi hagi un minim de votacions aquesta pagina no es accesible</p>
            </>
          )}
      </Container>
    )
  }
}

class FilmList extends Component {
  constructor(props) {
    super(props);
    this.films = props.films;
    this.type = props.type ? props.type : 'LLARGS';
    this.filter = props.filter ? props.filter : 'comite';
    this.userRanking = props.userRanking ? props.userRanking : 'oficial';
    switch( userRanking) {
      case "oficial":
        this.isJury = U.isUserJuryOficial()
      break;
      case "jove":
        this.isJury = U.isUserJuryYoung()
      break;
      case "centres":
        this.isJury = U.isUserJurySchool()
      break;
    }


  }
  render() {
    const films = this.films;
    return (
      <>
      <h2 className='my-4'>Top { MESSAGES[this.type].charAt(0).toUpperCase() }{ MESSAGES[this.type].slice(1) }<br /><small>Endreçats de més valorats a menys</small></h2>
      <FilmsListFilters urlBase={ ROUTES['TOP_' + this.type ] } filmType={this.type} filter={this.filter} userRanking={userRanking} filters={['user','school'] }  />
      {films.map(film => (
        <FilmsTopItem key={film.uid} film={film} filmType={filmType} />
      ))}
    </>
    )
  }
} 

class FilmsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      films: [],
      API: null
    };

    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.shorts();
        this.filmTypeName = 'CURTS';
      break;
      case 'llargs':
      default:
        this.state.API = this.props.firebase.films();
        this.filmTypeName = 'LLARGS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.docs();
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.schools();
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.musics();
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.API = this.props.firebase.youngs();
        this.filmTypeName = 'JOVES';
      break;
    }
  }
  getFilmsByIsSelectable( films, value ) {
    let filmsSelectable = [];
    if (films !== null) {
      for ( var k = 0; k < films.length; k++) {
        if (films[k].isSelectable === value) {
          filmsSelectable.push(films[k])
        }
      }
    }
    return filmsSelectable;
  }
  updateFilmList() {
    this.setState({ loading: true });
    this.state.API.on('value', snapshot => {
      const filmObject = snapshot.val();
      if (filmObject) {
        const filmList = Object.keys(filmObject).map(key => ({
          ...filmObject[key],
          uid: key,
        }));
        
        // GET USER RANK AND ATTACH IT TO THE ARRAY

        let scopeKeeper = this;
        let response = [];
        let userList = [];
        let userListIds = [];
        let userTotal = 0;

        // Get list of users
        scopeKeeper.props.firebase.users().once('value', snapshotUserList => {
          if (snapshotUserList.val()) {
            userList = snapshotUserList.val() 
            userListIds = Object.keys(userList);
          } else {
            userList = []
          }
        
          // For each item on the film list...
          filmList.forEach( function(element, index, array) {
            
            element.userFilmRanks = [];
            element.userFilmScore = 0;
            element.userFilmScoreRank = 0;
            element.userFilmPeopleNumber = 0;
            element.userFilmPeopleNames = '';

            let score = false;

            userListIds.forEach( function(userElement, userIndex, userArray) {  
              
              // Get the user opinion value
              scopeKeeper.props.firebase.database().ref(CONFIG.USER_NOTES + userElement + '/' + element.uid).once('value', snapshotUserFilm => {
                
                if ( filter === 'comite' || ( userList[userElement].roles && Object.values(userList[userElement].roles).indexOf(filter) !== -1 && (( this.isJury ) ||  U.isUserAdmin())) ) {

                  if (snapshotUserFilm.val() && element) {
                    score = snapshotUserFilm.val().userFilmRank;
                  } else {
                    score = false;
                  }
                  element.userFilmRanks[userElement] = {
                    name: userList[userElement].username,
                    score: score,
                    uid: userElement
                  }

                  if (score > 0 ) {
                    element.userFilmPeopleNumber++
                    if (element.userFilmPeopleNumber > userTotal) {
                      userTotal = element.userFilmPeopleNumber;
                    }
                    element.userFilmScore += score
                    element.userFilmRank = ( element.userFilmPeopleNumber <= 1 ? (element.userFilmScore / 2).toFixed(2) : (element.userFilmScore / element.userFilmPeopleNumber).toFixed(2) )
                    element.userFilmScoreRank = ( element.userFilmPeopleNumber >= (Math.round(userTotal / 3) + 1) ? element.userFilmRank : (element.userFilmRank / element.userFilmPeopleNumber).toFixed(2) )
                    element.userFilmPeopleNames += score + ' ' + userList[userElement].username + '\n';
                  } 
                }

                if (response.indexOf(element.uid) === -1) {
                  response.push(element.uid);
                }
   
                if (response.length >= filmList.length ) {
                    let filmSortList =  _.sortBy( filmList, 'userFilmScoreRank' ).reverse();
                    
                    let filmListFiltered = {};
                    switch(window.location.pathname.split('/')[3]) {
                      case 'secundaria':
                        filmListFiltered = _.filter( filmSortList, function(film) { return (film.filmSchoolLevel === 'secundaria' || film.filmSchoolLevel === 'batxillerat' ) } );
                      break;
                      case 'primaria':
                        filmListFiltered = _.filter( filmSortList, function(film) { return (film.filmSchoolLevel === 'primaria' || film.filmSchoolLevel === 'infantil' ) } );
                      break;
                      default:
                        filmListFiltered = filmSortList;
                      break;
                    }

                    scopeKeeper.setState({
                      films: filmListFiltered,
                      loading: false
                    });
                }

              })
            }) 

          });
        }); 
        // convert messages list from snapshot
        
      } else {
        this.setState({ films: null, loading: false });
      }
    });
  }
  componentDidMount() { 
    
    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'llargs':
      default:
        this.state.type = 'LLARGS';
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.type = 'JOVES';
      break;
    }  
   this.updateFilmList();
  }
  UNSAFE_componentWillReceiveProps() {
    this.updateFilmList();
  }
  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }
  render() {
    const { films, loading, type } = this.state;
    return (
      <div>
        {films ? (
          <React.Fragment>
            {loading ? (
              <Loading />
            ) : (
                <FilmList films={films} type={type} filter={filter} userRanking={userRanking} />
            )}
          </React.Fragment>
        ) : (
          <Alert variant="warning"> <PiWarningDiamondFill />No hi ha produccions inscrites en aquesta categoría.</Alert>
        )}
      </div>
    );
  }
}
const Films = withFirebase(FilmsBase);

const condition = () => { return !U.isUserAnonymous()}

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(HomePage);