import React, { Component } from "react";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { withAuthorization, withEmailVerification } from "../Session";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import * as CONFIG from "../../constants/config";
import * as ROUTES from "../../constants/routes";
import * as MESSAGES from "../../constants/messages";
import * as U from "../../constants/utilities";

import Loading from "../Loading";
import { Alert, Card, Container, InputGroup } from "react-bootstrap";
import { PiEnvelope, PiPhone, PiWarningDiamondFill } from "react-icons/pi";

let filmType = "";
let filmUid = "";
let year = ""

class FilmsSelectedStuff extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      API: null,
      type: null,
      validated: null,
      userConfirmed: false,
      edition: props.match.params.year
    };

    filmType = props.match.params.type ? props.match.params.type : "llargs";
    filmUid = props.match.params.uid ? props.match.params.uid : null;
    year = props.match.params.year;
    
    if ( U.activeEdition() !== props.match.params.year  ) {
      U.setActiveEdition(year);
    }

    if (U.isUserFilmaker() && U.authUser().roles && U.authUser().roles.FILMAKER  && U.authUser().roles.FILMAKER.map(e => e.uid).indexOf(filmUid) !== -1) {
      this.redirectToMaterial()
    }


    switch (filmType) {
      case "curts":
        this.state.type = "CURTS";
        this.state.API = this.props.firebase.short(filmUid);
        this.filmTypeName = "CURTS";
        break;
      case "documentals":
        this.state.API = this.props.firebase.doc(filmUid);
        this.state.type = "DOCS";
        this.filmTypeName = "DOCS";
        break;
      case "centres-educatius":
        this.state.API = this.props.firebase.school(filmUid);
        this.filmTypeName = "CENTRES";
        this.state.type = "CENTRES";
        break;
      case "videoclips":
        this.state.API = this.props.firebase.music(filmUid);
        this.state.type = "VIDEOCLIPS";
        this.filmTypeName = "VIDEOCLIPS";
        break;
      case "jove":
        this.state.API = this.props.firebase.young(filmUid);
        this.filmTypeName = "JOVES";
        this.state.type = "JOVES";

        break;
      case "llargs":
      default:
        this.state.API = this.props.firebase.film(filmUid);
        this.filmTypeName = "LLARGS";
        this.state.type = "LLARGS";
        break;
    }

  }

  componentDidMount() {
    this.setState({ loading: true });

    this.state.API.once("value", (snapshot) => {
      if (snapshot.val()) {
        // convert messages list from snapshot
        this.setState({
          film: snapshot.val(),
          loading: false,
        });
        this.setState({
          isSelectable: this.state.film.isSelectable,
          isSelected: this.state.film.isSelected,
        });
      } else {
        U.setActiveEdition(year, true);
        this.setState({ film: null, loading: false });
      }
    });
  }

  mungeEmailAddress(s) {
    var i = s.indexOf("@");
    var startIndex = (i * 0.2) | 0;
    var endIndex = (i * 1) | 0; // i * .9 | 0
    return (
      s.slice(0, startIndex) +
      s.slice(startIndex, endIndex).replace(/./g, "*") +
      s.slice(endIndex)
    );
  }

  redirectToMaterial() {
    window.location = ROUTES.ITEM_STUFF + '/' + filmType + '/' + this.state.edition + '/' + filmUid;
  }


  sendFormConfirmUser(e) {

    this.setState({ disableForm: true })

    e.preventDefault();

    const form = e.currentTarget;

    const username = this.state.film.contactName ;
    const email = form.elements["email"].value;
    const password = form.elements["phone"].value;
    const scopeKeeper = this;
    const filmObject = {
      uid: filmUid,
      type: filmType,
      year: year,
      name: this.state.film ? this.state.film.filmName : ''
    }

    this.setState({ validated: true });


    if (form.checkValidity() === false) {
      e.stopPropagation();
      this.setState({ disableForm: false })
    } else if (this.state.film) {

      if (form.elements["email"].value === this.state.film.contactEmail && form.elements["phone"].value === this.state.film.contactPhone) {

        this.props.firebase
          .doSignInWithEmailAndPassword(email,password )
          .then (authUser => {
            this.props.firebase.user(authUser.user.uid)
            .once('value')
            .then(snapshot => {
              const dbUser = snapshot.val();

              // default empty roles
              if (dbUser && !dbUser.roles) {
                dbUser.roles = { FILMAKER : []};
              }
              // Add current production id to filmaker ownership
              if (dbUser && dbUser.roles && dbUser.roles.FILMAKER && dbUser.roles.FILMAKER.map(e => e.uid).indexOf(filmUid) === -1) {
                dbUser.roles.FILMAKER.push(filmObject);
              }

              this.props.firebase.user(authUser.user.uid).set(dbUser).then( () => {
                this.authUser = dbUser;
                this.redirectToMaterial();
              });
              
            })

          })
          .catch(error => {

            const roles = {FILMAKER : [filmObject]}
            this.props.firebase
            .doCreateUserWithEmailAndPassword(this.state.film.contactEmail, this.state.film.contactPhone)
            .then(authUser => {
              // Create a user in your Firebase realtime database
              this.props.firebase.user(authUser.user.uid).set({
                username,
                email,
                roles,
              }).then( () => {
                scopeKeeper.redirectToMaterial();
              });
              
            })
            .catch(error => {
              this.setState({ error: error, disableForm: false });
            });
          });      
      } else {
        this.setState({ error: true, disableForm: false });
      }
    }

  }

  render() {
    const { film, isSelected, error, disableForm } = this.state;
    return (
      <Container>
        {film && isSelected ? (
          <>
            <Container className="pt-4">
            <Row className="justify-content-md-center">
              <Col lg={6}>
            <h2 className="my-4">Enviament de materials <small>Accedeix a la gestió d'enviament de materials amb <strong className="text-info">les dades de la persona de contacte</strong> de la teva producció</small></h2>
                <Card bg="light">
                  <Card.Body>


                <Form
                  noValidate
                  validated={this.state.validated}
                  id="formConfirmUser"
                  onSubmit={this.sendFormConfirmUser.bind(this)}
                >
                  <Row>
                    <Col>
                      { error && (
                        <Alert variant="danger"> <PiWarningDiamondFill /> <strong>L'email i/o el telèfon no es corresponen</strong> amb els que vas fer servir durant el registre. Si no te'n recordes o no pots accedir contacta a <a href="mailto:programacio@fic-cat.cat">programacio@fic-cat.cat</a></Alert>
                      )}
                      <Form.Group id="mail" className="pb-3">
                        <Form.Label>
                          Confirmar Email:{" "}
                          <strong>
                            {this.mungeEmailAddress(film.contactEmail)}
                          </strong>
                        </Form.Label>
                        <InputGroup hasValidation>
                        <InputGroup.Text><PiEnvelope /></InputGroup.Text>
                        <Form.Control
                          required
                          disabled={disableForm}
                          name="email"
                          type="email"
                          placeholder="Ex: ningu@jmail.com"
                        />
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          {MESSAGES.FIELD_REQUIRED}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group id="phone" className="pb-3">
                        <Form.Label>
                          Confirmar telefon:{" "}
                          <strong>
                            {this.mungeEmailAddress(film.contactPhone)}
                          </strong>
                        </Form.Label>
                        <InputGroup hasValidation>
                        <InputGroup.Text><PiPhone /></InputGroup.Text>
                        <Form.Control
                          required
                          name="phone"
                          type="number"
                          disabled={disableForm}
                          placeholder="Ex: 555 55 55 55"

                        />
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          {MESSAGES.FIELD_REQUIRED}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                      type="submit"
                      className="btn btn-lg btn-success mb-4"
                    >
                      <strong>Accedir</strong>
                    </Button>
                </Form>
                </Card.Body>
                </Card>
                </Col>
            </Row>
              </Container>
          </>
        ) : (
          <Loading />
        )}
      </Container>
    );
  }
}

export default withFirebase(FilmsSelectedStuff);
