import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as U from '../../constants/utilities';

import './index.scss';



class Loading extends Component {
  constructor(props) {
    super(props);

  }


  render () {
    return (
      <div className='Loading'>
          <div className='content'>
            <img alt="Carregant..." src="/img/loading.gif" />
            <h1>Carregant...</h1>
          </div>
      </div>
    )
  }
}

export default compose(
  withFirebase
)(Loading);